import React, {useEffect, useState} from 'react';
import {StyledDashboard} from "./styled";
import Search from "../components/common/Search";
import {useDataContext} from "../context/DataContext";
import Loader from "../components/common/Loader";
import CategoryRow from "../components/specific/dashboard/CategoryRow";
import {useUtilitiesContext} from "../context/UtilitiesContext";

const DashboardPage: React.FC = () => {
    const { loading: dataLoading, categoriesMap } = useDataContext()
    const { setNavbarTitle, transparentLoading, dashboardNavigateTo } = useUtilitiesContext()
    const [search, setSearch] = useState('')

    useEffect(() => {
        setSearch('')
        setNavbarTitle({title: "My Menu", iconRight: {type: "PLUS", menu: [{body: "Aggiungi prodotto", onClick: () => dashboardNavigateTo(4)}]}})
    }, []);

    const searchFunction = (value: string) => {
        setSearch(value)
    }

    return (
        <>
        {(dataLoading) ? <Loader allPage={true}/> :
           <StyledDashboard>
               {transparentLoading && <Loader allPage={true} transparent={true}/>}
               <Search search={search} onChange={searchFunction}/>
               <div className={"categoriesContainer"}>
                   {Array.from(categoriesMap.values()).map((item, index) =>
                       <CategoryRow search={search} category={item} key={index} />
                   )}
               </div>
           </StyledDashboard>}
            </>
    );
};

export default DashboardPage;