import styled from "styled-components";
import React, {useEffect} from "react";
import {MenuIconRight} from "../../types/context";

interface MenuProps {
    isOpen: boolean;
    onClose: () => void;
    menu: MenuIconRight[]
}

const MenuContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 100%;
  max-width: 500px;
  height: 200px;
  background-color: white;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
  transition: bottom 0.3s ease-in-out;
  z-index: 1000;
`;

const MenuContent = styled.div`
  padding: 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
  margin: 10px 0;
  font-size: 18px;
  cursor: pointer;
`;

const MoreVoiceMenu: React.FC<MenuProps> = ({ isOpen, onClose, menu }) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Element;
            if (!target.closest('.menu-container')) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    const click = (onClick: () => void) => {
        onClose()
        onClick()
    }

    return (
        <MenuContainer isOpen={isOpen} className="menu-container">
            <MenuContent>
                <MenuList>
                    {menu && menu.map((element) =>
                        <MenuItem onClick={() => click(element.onClick)}>{element.body}</MenuItem>
                    )}
                </MenuList>
            </MenuContent>
        </MenuContainer>
    );
};

export default MoreVoiceMenu;