import styled from "styled-components";

export const StyledCategoryRow = styled.div`
    
    //@media only screen and (max-width: 550px)  {
        .categoryRow {
            width: 100%;
            font-size: 1.5rem;
            text-align: center;
            margin: 0.5rem 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 10px 10px 10px 4%;
            border-radius: 5px;
            font-weight: bold;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .toggleIcon {
            font-size: 1.2rem;
            cursor: pointer
        }
        .box {
            display: flex;
            flex-direction: column;
            padding: 10px 10px 10px 4%;
            box-sizing: border-box;
            background-color: white;
            border-bottom: 1px solid #ccc;
        }

        .boxDiv {
            width: 80px;
            height: 80px;
        }
    
        .boxRow {
            width: 100%;
            display: flex;
            flex-direction: row;
            height: 70px;
        }

        .box img {
            border-radius: 5px;
        }

        .box .divImg{
            width: 80px;
            height: 80px;
        }

        .productInfo {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 80px - 0.5rem);
            margin-left: 0.5rem;
        }

        .noProducts {
            text-align: center;
            padding: 10px;
            color: #888;
        }

        .productName {
            font-weight: bold;
            font-size: 1.2rem;
        }

        .ingredientList {
            color: gray;
            font-size: 0.7rem;
        }

        .price {
            text-align: right;
            margin-right: 2%;
            font-weight: bold;
            font-size: 1.1rem;
        }

        .iconUpDown{
            position: absolute;
            right: 4%;
        }
    //}
`

export const StyledSummaryRow = styled.div`
    display: flex;
    flex-direction: column;
    
    //@media only screen and (max-width: 550px) {
        .title {
            width: 100%;
            font-size: 1.5rem;
            text-align: center;
            margin: 0.5rem 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 10px 10px 10px 4%;
            border-radius: 5px;
            font-weight: bold;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .box {
            display: flex;
            flex-direction: row;
            padding: 10px 10px 10px 4%;
            box-sizing: border-box;
            background-color: white;
            border-bottom: 1px solid #ccc;
        }

        .box img {
            border-radius: 5px;
        }

        .boxDiv {
            width: 80px;
            height: 80px;
        }

        .name {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 80px - 0.5rem);
            margin-left: 0.5rem;
            font-weight: bold;
        }

        .iconRight{
            position: absolute;
            right: 4%;
        }
    //}
    
`
