import React, {useEffect, useState} from 'react';
import {useDataContext} from "../context/DataContext";
import {useUtilitiesContext} from "../context/UtilitiesContext";
import {useLoginContext} from "../context/LoginContext";
import Loader from "../components/common/Loader";
import {StyledPage} from "./styled";

const InfoPage: React.FC = () => {
    const { loading: dataLoading, styleJson } = useDataContext()
    const { setNavbarTitle, transparentLoading , dashboardNavigateTo } = useUtilitiesContext()
    const { user } = useLoginContext()

    useEffect(() => {
        setNavbarTitle({back: {back: true, custom: false}, title: "Informazioni", iconRight: {type: "NONE", menu: []}})
    }, []);

    return (
        <>
        {(dataLoading) ? <Loader allPage={true}/> :
            <StyledPage>

                <div className="box">
                    <div className="title">Dato1</div>
                    <div>
                        <input
                            placeholder="Inserisci"
                            value={''}
                            type={'text'}
                            onChange={(e) => console.log('')}
                        />
                    </div>
                </div>

                <div className={"line"}></div>

                <div className="box">
                    <div className="title">Dato2</div>
                    <div>
                        <input
                            placeholder="Inserisci"
                            value={''}
                            type={'text'}
                            onChange={(e) => console.log('')}
                        />
                    </div>
                </div>

                <div className={"line"}></div>

                <div className="box">
                    <div className="title">Dato3</div>
                    <div>
                        <input
                            placeholder="Inserisci"
                            value={''}
                            type={'text'}
                            onChange={(e) => console.log('')}
                        />
                    </div>
                </div>

                <div className={"line"}></div>

                <div className="box">
                    <div className="title">Dato4</div>
                    <div>
                        <input
                            placeholder="Inserisci"
                            value={''}
                            type={'text'}
                            onChange={(e) => console.log('')}
                        />
                    </div>
                </div>

                <div className={"line"}></div>

                <div className={"lastLine"}></div>

                <div
                    className={('' === '') ? "saveButtonNotActive" : "saveButtonActive"}
                    onClick={() => console.log('test')}
                >
                    Salva
                </div>
            </StyledPage>
        }
        </>
    );
};

export default InfoPage;