import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translations/en.json';
import itTranslation from './translations/it.json';
import deTranslation from './translations/de.json';
import esTranslation from './translations/es.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslation,
            },
            it: {
                translation: itTranslation,
            },
            de: {
                translation: deTranslation,
            },
            es: {
                translation: esTranslation,
            },
        },
        lng: 'it', // Lingua predefinita
        fallbackLng: 'it',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
