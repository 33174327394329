import React, {createContext, useState, ReactNode, useContext, useEffect, useMemo} from 'react';
import {CategoryContextType, CategoryFormData} from "../types/context";
import {Category, Product, ProductFormData} from "../types/types";
import {ManageElements} from "./ProductContext";
import {useDataContext} from "./DataContext";
import {useNotification} from "./NotificationContext";

const CategoryContext = createContext<CategoryContextType | undefined>(undefined);

export const useCategoryContext = () => {
    const context = useContext(CategoryContext);
    if (!context) {
        throw new Error('useCategoryContext must be used within a CategoryProvider');
    }
    return context;
};

export const CategoryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const { productsByCategory } = useDataContext()

    const { addNotification } = useNotification()

    const [categorySelected, setCategorySelected] = useState<Category | null>(null)
    const [manageProducts, setManageProducts] = useState<ManageElements>({existing: [], added: [], removed: []})
    const [isNew, setIsNew] = useState<boolean>(false)
    const [categoryTmpFormData, setCategoryTmpFormData] = useState<CategoryFormData | null>(null)
    const [tmpId, setTmpId] = useState<number | null>(null)

    const loadTmpCategory = (category: Category) => {
        setCategorySelected(category);
        setManageProducts({existing: productsByCategory.get(category.id) || [], added: [], removed: []})
        setIsNew(false)
    }

    const resetTmpCategory = () => {
        if(categorySelected){
            setCategorySelected(null);
            setManageProducts({existing: [], added: [], removed: []})
            setIsNew(false)
        }
    }

    const setTmpIsNew = () => {
        setIsNew(true)
    }

    const addProduct = (idProduct: number) => {
        setManageProducts(prevManageProduct => {
            const updatedRemoved = prevManageProduct.removed.filter(value => value !== idProduct);
            const updatedAdded = new Set(prevManageProduct.added);

            if (!prevManageProduct.existing.includes(idProduct)) {
                updatedAdded.add(idProduct);
            }

            return {
                ...prevManageProduct,
                removed: updatedRemoved,
                added: Array.from(updatedAdded)
            };
        });
        addNotification('success', 'Prodotto aggiunto')
    };

    const addTmpId = (id: number | null) => {
        setTmpId(id)
    }

    const removeProduct = (idProduct: number) => {
        setManageProducts(prevManageProduct => {
            const updatedAdded = prevManageProduct.added.filter(value => value !== idProduct);
            const updatedRemoved = new Set(prevManageProduct.removed);
            updatedRemoved.add(idProduct);

            return {
                ...prevManageProduct,
                added: updatedAdded,
                removed: Array.from(updatedRemoved)
            };
        });
        addNotification('success', 'prodotto rimosso')
    };

    const loadTmpCategoryFormData = (category: CategoryFormData) => {
        setCategoryTmpFormData(category)
    }

    const dashboardMeno = useMemo<CategoryContextType>(() => ({
        loadTmpCategory,
        loadTmpCategoryFormData,
        setTmpIsNew,
        resetTmpCategory,
        addProduct,
        removeProduct,
        addTmpId,
        manageProducts,
        isNew,
        categorySelected,
        categoryTmpFormData
    }), [
        manageProducts,
        isNew,
        categorySelected,
        categoryTmpFormData,
    ])

    return (
        <CategoryContext.Provider value={dashboardMeno}>
            {children}
        </CategoryContext.Provider>
    );
};