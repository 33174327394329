import React, { useEffect, useState } from 'react';
import { StyledElementsPage } from './styled';
import { useDataContext } from '../context/DataContext';
import { useUtilitiesContext } from '../context/UtilitiesContext';
import Loader from '../components/common/Loader';
import Search from '../components/common/Search';
import {Allergen, Ingredient, Product, Tag} from '../types/types';
import {ManageElements} from "../context/ProductContext";
import {useCategoryContext} from "../context/CategoryContext";

interface ElementsProductPageProps {
    listType: "IN_PRODUCT" | "ADD_EXISTING";
}

interface RowProps {
    name: string;
    //icon: IconType;
    click: () => void;
}

const Row: React.FC<RowProps> = ({ name, click }) => {
    return (
        <div className='row1' onClick={click}>
            <div className='name'>{name}</div>
            <div className='icon'>
                {/*icon.icon === "PLUS" && <FontAwesomeIcon icon={faCirclePlus} />}
                {icon.icon === "MINUS" && <FontAwesomeIcon icon={faCircleMinus} />*/}
            </div>
        </div>
    );
};

const ElementsProductPage: React.FC<ElementsProductPageProps> = ({listType}) => {
    const { loading: dataLoading, productsMap } = useDataContext();
    const { localname, goBack, transparentLoading, dashboardNavigateTo, setNavbarTitle } = useUtilitiesContext();
    const { isNew, categorySelected, categoryTmpFormData, removeProduct, manageProducts, loadTmpCategoryFormData, loadTmpCategory, resetTmpCategory, setTmpIsNew, addProduct } = useCategoryContext()

    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        reload()
    }, []);

    useEffect(() => {
        reload()
    }, [listType]);

    const reload = () => {
        setSearch('')
        if(!categorySelected && !isNew)
            window.location.href = "/" + localname //TODO: aggiungere url alla categoria che si stava modificando
        let title = "Piatti";

        if(listType === "ADD_EXISTING")
            title = "Aggiungi ".concat(title)

        setNavbarTitle({back: {back: true, custom: false},title: title, iconRight: {type: listType === "IN_PRODUCT" ? "PLUS" : "NONE", menu: [{body: "Aggiungi esistente", onClick: () => dashboardNavigateTo(19, categorySelected?.id + "/products/addExisting")}, {body: "Aggiungi nuovo", onClick: () => dashboardNavigateTo(19, categorySelected?.id + "/products/addNew")}]}})
    }

    const sortElements = (elements: Product[]) => {
        return elements.sort((a, b) => {

            // in ordine alfabetico
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    };

    const remove = (id: number) => {
        removeProduct(id)
    }

    const add = (id: number) => {
       addProduct(id)
    }

    const renderElements = (elements: Product[]) => {
        return sortElements(
            elements
                .filter((element: Product) =>
                    listType === "IN_PRODUCT" ?
                        (manageProducts.existing.includes(element.id || 0) || manageProducts.added.includes(element.id || 0)) && !manageProducts.removed.includes(element.id || 0)
                        :
                        (!manageProducts.existing.includes(element.id || 0) && !manageProducts.added.includes(element.id || 0)) || manageProducts.removed.includes(element.id || 0))
                .filter((element) => search === '' || element.name.toLowerCase().includes(search.toLowerCase()))
        ).map((element) => {
            //const icon = getIcon(element.id);

            return (
                <Row
                    key={element.id}
                    name={element.name}
                    //icon={icon}
                    click={() => listType === 'IN_PRODUCT' ? remove(element.id || 0) : add(element.id || 0)}
                />
            );
        });
    };

    const searchElements = (value: string) => {
        setSearch(value)
    }

    return (
        <>
        {(dataLoading) ? <Loader allPage={true}/> :
        <StyledElementsPage>
            {transparentLoading && <Loader allPage={true} transparent={true}/>}
            <Search search={search} onChange={searchElements} />
            {productsMap && renderElements(Array.from(productsMap.values()))}
            <div className={'saveButtonActive'} onClick={() => goBack()}>Indietro</div>
        </StyledElementsPage>}
            </>
    );
};

export default ElementsProductPage;
