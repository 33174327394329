import React, {createContext, useState, ReactNode, useContext, useEffect, useMemo} from 'react';
import { useLoginContext } from './LoginContext';
import {NavbarTitle, UtilitiesContextType} from "../types/context";
import {useNavigate, useParams} from "react-router-dom";
import {generateSessionId, getPageFromId, getSessionId} from "../utils/utils";

const UtilitiesContext = createContext<UtilitiesContextType | undefined>(undefined);

export const useUtilitiesContext = () => {
    const context = useContext(UtilitiesContext);
    if (!context) {
        throw new Error('useUtilitiesContext must be used within a UtilitiesProvider');
    }
    return context;
};

export const UtilitiesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    //const { token } = useLoginContext();
    const { localname,  } = useParams();
    const { logout } = useLoginContext()

    const [temporaryData, setTemporaryData] = useState<any>(null);
    //const [tmpStyleJson, setTmpStyleJson] = useState<Styles | null>(null);

    const [loading, setLoading] = useState<boolean>(true)
    const [navbarTitle, setNavbarTitle] = useState<NavbarTitle>(        {title: "", iconRight: {type: "NONE", menu: []}})
    const [transparentLoading, setTransparentLoading] = useState<boolean>(false)
    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    //state che mi permette di riavviare la pagina dopo 1000 secondi se appesantita
    const [countPage, setCountPage] = useState(1000);

    const navigate = useNavigate()

    useEffect(() => {

        if (!getSessionId() || getSessionId() === "0000") {
            generateSessionId();
        }

        // Countdown interval
        const countdownInterval = setInterval(() => {
            setCountPage(prevCount => prevCount - 1);
        }, 1000);


        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            clearInterval(countdownInterval);
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    useEffect(() => {
        if (countPage === 0) {
            window.location.reload(); // Ricarica la pagina
        }
    }, [countPage]);

    const dashboardNavigateTo = (idPage: number, additionalValue?: string, navbarName?: NavbarTitle) => {
        let tmp = 0;
        if(idPage === 90){
            logout();
            navigate('/login')
            return
        }
        //if(idPage >= 0 && idPage <= 9)
        tmp = idPage;
        const pageTmp = getPageFromId(idPage);
        let url = "/" + localname + "/" + pageTmp;
        if(additionalValue)
            url += "/" + additionalValue
        if(navbarName)
            setNavbarTitle(navbarName)
        navigate(url)
        setSearch("")
        setCurrentPage(tmp)
    }

    const goBack = () => {
        navigate(-1)
    }


    const dashboardMeno = useMemo<UtilitiesContextType>(() => ({
        dashboardNavigateTo,
        setTemporaryData,
        setLoading,
        setTransparentLoading,
        setSearch,
        setCurrentPage,
        setNavbarTitle,
        goBack,
        temporaryData,
        localname,
        loading,
        transparentLoading,
        search,
        currentPage,
        windowWidth,
        navbarTitle
    }), [windowWidth, currentPage, search, transparentLoading, loading, localname, temporaryData, navbarTitle])

    return (
        <UtilitiesContext.Provider value={dashboardMeno}>
            {children}
        </UtilitiesContext.Provider>
    );
};