import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../context/ThemeContext';
import { Theme } from "../theme/theme";
import { useLoginContext } from "../context/LoginContext"; // Adjust the import based on your project structure
import Loader from "../components/common/Loader";
import { Link } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import {FormControlLabel} from "@mui/material";
import GeneralPopupPage from "../components/common/GeneralPopupPage";


interface RegisterFormValues {
    firstName: string;
    lastName: string;
    businessName: string;
    phoneNumber: string;
    email: string;
    password: string;
    confirmPassword: string;
    consent: boolean;
    avatar: File | null;
}

const label = { inputProps: { 'aria-label': 'Accetto i termini e condizioni' } };

const RegisterPage: React.FC = () => {
    const { register, loading, transparentLoading } = useLoginContext();
    const { theme } = useContext(ThemeContext) || { theme: {} };
    const [formValues, setFormValues] = useState<RegisterFormValues>({
        firstName: '',
        lastName: '',
        businessName: '',
        phoneNumber: '',
        email: '',
        password: '',
        confirmPassword: '',
        consent: false,
        avatar: null
    });
    const [error, setError] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, type, checked, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    useEffect(() => {
        try {
            const doc = document.getElementById('root');
            if (doc) {
                doc.style.maxWidth = "100%";
            }
        } catch (error) {
            // Handle error if needed
        }
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { firstName, lastName, businessName, phoneNumber, email, password, confirmPassword, consent, avatar } = formValues;

        if (!firstName || !lastName || !businessName || !phoneNumber || !email || !password || !confirmPassword) {
            setError('Compila tutti i campi');
            return;
        }

        if (password !== confirmPassword) {
            setError('Le password non corrispondono');
            return;
        }

        if (!consent) {
            setError('Devi accettare il trattamento dei dati');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('name', firstName);
            formData.append('surname', lastName);
            formData.append('localname', businessName);
            formData.append('phone', phoneNumber);
            formData.append('email', email);
            formData.append('password', password);
            if(avatar)
                formData.append('file', avatar); // Aggiungi l'immagine al formData

            // Chiamata al backend per registrare l'utente
            const tmp = await register(formData);
            setError(tmp);

        } catch (error) {
            setError('Registrazione non riuscita.');
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFormValues(prevValues => ({
                ...prevValues,
                avatar: e.target.files![0], // Aggiorna lo stato con il file selezionato
            }));
        }
    };


    return (
        <>
            {loading ? <Loader allPage={true} /> :
                error === "Success" ? <GeneralPopupPage title={"Registrazione effettuata con successo!"} body={"Grazie per esserti registrato! Un'email di conferma è stata inviata al tuo indirizzo di posta elettronica. Ti preghiamo di controllare la tua casella di posta e seguire le istruzioni per verificare il tuo account. Una volta confermata l'email, potrai accedere al tuo account e iniziare a utilizzare i nostri servizi."}/>:
                <RegisterContainer theme={theme}>
                    {transparentLoading && <Loader allPage={true} transparent={true} />}
                    <Logo src="/images/WeitLogo.png" alt="WeitLogo" />
                    <h2 style={{ margin: '1.5rem 0' }}>Registrati</h2>
                    <RegisterForm onSubmit={handleSubmit}>
                        <FormGroup>
                            {formValues.avatar && (
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                                    <ImagePreview src={URL.createObjectURL(formValues.avatar)} alt="Anteprima logo" />
                                </div>
                            )}
                            <label htmlFor="avatar">Carica il logo del locale:</label>
                            <Input
                                type="file"
                                id="avatar"
                                name="avatar"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="firstName">Nome:</label>
                            <Input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formValues.firstName}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="lastName">Cognome:</label>
                            <Input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formValues.lastName}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="businessName">Nome del Locale:</label>
                            <Input
                                type="text"
                                id="businessName"
                                name="businessName"
                                value={formValues.businessName}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="phoneNumber">Numero di Telefono:</label>
                            <Input
                                type="text"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formValues.phoneNumber}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="email">Email:</label>
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="password">Password:</label>
                            <Input
                                type="password"
                                id="password"
                                name="password"
                                value={formValues.password}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="confirmPassword">Conferma Password:</label>
                            <Input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={formValues.confirmPassword}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroupCheck>
                            <FormControlLabel style={{fontSize: '24px'}} control={<Checkbox checked={formValues.consent} name='consent' onChange={handleChange} />} label={label.inputProps["aria-label"]} />
                        </FormGroupCheck>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                        <SubmitButton type="submit">Registrati</SubmitButton>
                    </RegisterForm>
                    <LoginLink>
                        <Link to="/login">Hai già un account? Accedi</Link>
                    </LoginLink>
                </RegisterContainer>
            }
        </>
    );
};

const RegisterContainer = styled.div<{ theme: Theme }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(135deg, #f0f4f8, #d9e2ec);
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    padding: 20px;
`;

const Logo = styled.img`
    width: 150px;
`;

const RegisterForm = styled.form`
    width: 100%;
    max-width: 400px;
    padding: 30px;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
`;

const FormGroup = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const FormGroupCheck = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Input = styled.input`
    width: 100%;
    padding: 9px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;

    &:focus {
        border-color: ${({ theme }) => theme.colors.primary};
        outline: none;
    }
`;

const SubmitButton = styled.button`
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary + 'b3'};
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }
`;

const ErrorMessage = styled.p`
    color: ${({ theme }) => theme.colors.error};
    margin-bottom: 20px;
    font-size: 14px;
`;

const LoginLink = styled.div`
    margin-top: 20px;
    a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: none;
        font-size: 16px;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const ImagePreview = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 20px;
`;


export default RegisterPage;
