import React, {useEffect, useState} from 'react';
import {useDataContext} from "../context/DataContext";
import Loader from "../components/common/Loader";
import {StyledPage} from "./styled";
import {useParams} from "react-router-dom";
import {handleChangePrice} from "../utils/utils";
import {useUtilitiesContext} from "../context/UtilitiesContext";
import DeleteObj from "../components/common/DeleteObj";
import i18n from "i18next";
import {Switch} from "@mui/material";
import {useProductContext} from "../context/ProductContext";
import ingredientPage from "./IngredientPage";
import {useNotification} from "../context/NotificationContext";
import {addIngredient, removeIngredient, updateIngredient} from "../utils/api";

interface IngredientPageProps {
    isUpdate?: boolean
    inProduct?: boolean
}

const IngredientPage: React.FC<IngredientPageProps> = ({isUpdate, inProduct}) => {
    const { loading: dataLoading, ingredientsOperation, ingredientsMap, ingredientInProducts} = useDataContext()
    const { idIngredient } = useParams()
    const {localname, transparentLoading, setNavbarTitle, setTransparentLoading, goBack, dashboardNavigateTo} = useUtilitiesContext()

    const [id, setId] = useState<number>(0)
    const [name, setName] = useState<string>('')
    const [price, setPrice] = useState<number>(0)
    const [displayValue, setDisplayValue] = useState<string>('0')
    const [allergens, setAllergens] = useState<number[]>([])
    const [available, setAvailable] = useState<boolean>(true)
    const [addable, setAddable] = useState<boolean>(true)
    const [frozen, setFrozen] = useState<boolean>(false)
    const { productSelected, isNew, addTmpId, addIngredient: addIngredientToList}  = useProductContext()

    const { addNotification } = useNotification()

    useEffect(() => {
        if(!isUpdate){
            setNavbarTitle({title: "Aggiungi Ingrediente", iconRight: {type: "NONE", menu: []}})
        }
        if(inProduct && !productSelected && !isNew){
            window.location.href = "/" + localname
        }

        if(inProduct){
            addTmpId(null)
        }
    }, []);

    useEffect(() => {
        if(!dataLoading && isUpdate){
            if(idIngredient && ingredientsMap.has(Number(idIngredient))) {
                const ingredient = ingredientsMap.get(Number(idIngredient))
                if(ingredient) {
                    setId(ingredient?.id || 0)
                    setName(ingredient?.name || "")
                    setPrice(ingredient?.price || 0)
                    setDisplayValue(ingredient?.price?.toString() || '0')
                    setAllergens(ingredient?.allergens || [])
                    setAvailable(ingredient?.available)
                    setAddable(ingredient?.addable)
                    setFrozen(ingredient?.frozen)
                    setNavbarTitle({title: ingredient?.name || "", iconRight: {type: "NONE", menu: []}})
                }
            }else{
                window.location.href = "/" + localname
            }
        }
    }, [dataLoading]);

    const updateSaveFunc = () => {
        if(isUpdate)
            updateFunc()
        else{
            save()
        }
    }

    const save = async () => {
        if(name === null || name === ""){
            addNotification('error', i18n.t("Notifications.errorName"))
            return
        }
        setTransparentLoading(true);
        const tmp: any = await addIngredient((name.charAt(0).toUpperCase() + name.slice(1)), price, addable, available, frozen, allergens);
        if(tmp){
            ingredientsOperation({operation: "ADD"}, {id: Number(tmp), name: (name.charAt(0).toUpperCase() + name.slice(1)), price: price, addable: addable, available: available, frozen: frozen});
            addNotification('success', i18n.t("Notifications.confirmed"))
            if(inProduct){
                addTmpId(Number(tmp))
                addIngredientToList(tmp)
                goBack()
            }else{
                dashboardNavigateTo(21)
            }
        }else{
            addNotification('error', i18n.t("Notifications.error"))
        }
        setTransparentLoading(false);
    }

    const updateFunc = async () => {
        if(name === null || name === ""){
            addNotification('error', i18n.t("Notifications.errorName"))
            return
        }
        setTransparentLoading(true);
        const tmp: any = await updateIngredient(id, name, price, frozen, available, addable, allergens);
        if(tmp){
            ingredientsOperation({operation: "UPDATE"}, {id: id, name: name, price: price, addable: addable, available: available, frozen: frozen, allergens: allergens})
            addNotification('success', i18n.t("Notifications.confirmed"))
            setTransparentLoading(false);
            dashboardNavigateTo(21)
        }else{
            addNotification('error', i18n.t("Notifications.error"))
        }
        if(transparentLoading)
            setTransparentLoading(false)
    }

    const deleteFunc = async () => {
        if(isUpdate && !transparentLoading){
            setTransparentLoading(true)
            const tmp: any = await removeIngredient(id);
            if(tmp){
                ingredientsOperation({operation: "REMOVE"}, undefined, id)
                setTransparentLoading(false)
                addNotification('success', i18n.t("Notifications.confirmed"));
                goBack()
            }else{
                addNotification('error', i18n.t("Notifications.error"));
            }
            if(transparentLoading)
                setTransparentLoading(false)
        }
    }

    return (
        <>
        {(dataLoading) ? <Loader allPage={true}/> :
            <StyledPage>
                {transparentLoading && <Loader allPage={true} transparent={true}/>}
                <div className={"box"}>
                    <div className={"title"}>
                        Nome Ingrediente
                    </div>
                    <input
                        placeholder="Inserisci il nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div className={"line"}></div>

                <div className="boxSwitch">
                    <div className="titleSwitch">Disponibile</div>
                    <div className={"switch"}>
                        <Switch
                            checked={available}
                            onChange={(e) => setAvailable(e.target.checked)}
                        />
                    </div>
                </div>

                <div className={"line"}></div>

                <div className="boxSwitch">
                    <div className="titleSwitch">Aggiungibile</div>
                    <div className={"switch"}>
                        <Switch
                            checked={addable}
                            onChange={(e) => setAddable(e.target.checked)}
                        />
                    </div>
                </div>

                <div className={"line"}></div>

                <div className="boxSwitch">
                <div className="titleSwitch">Surgelato</div>
                    <div className={"switch"}>
                        <Switch
                            checked={frozen}
                            onChange={(e) => setFrozen(e.target.checked)}
                        />
                    </div>
                </div>

                <div className={"line"}></div>

                <div className="box">
                    <div className="title">Prezzo</div>
                    <div>
                        <input
                            placeholder="Inserisci il prezzo"
                            value={"€ " + displayValue}
                            onChange={(e) => handleChangePrice(e, setDisplayValue, setPrice)}
                        />
                    </div>
                </div>

                {isUpdate && <><div className={"line"}></div>

                    <div className={"boxDelete"}>
                        <DeleteObj
                            remove={deleteFunc}
                            text={"Elimina Ingrediente"}
                            messageTitle={i18n.t('Dashboard.ingredients.deleteTitle')}
                            messageBody={ingredientInProducts(id) ? i18n.t('Dashboard.ingredients.deleteBodyWithProducts') : i18n.t('Dashboard.ingredients.deleteBody')} />
                    </div></>}

                <div className={"line"}></div>

                <div className={"lastLine"}></div>

                <div
                    className={(name === '') ? "saveButtonNotActive" : "saveButtonActive"}
                    onClick={() => name !== '' && updateSaveFunc()}
                >
                    Salva
                </div>
            </StyledPage>}
            </>
    );
};

export default IngredientPage;