import styled from 'styled-components';

// Definisci un'interfaccia per le props dei componenti
interface StyledImageProps {
    image: string;
}

interface ContentProps {
    overflow: boolean;
    charge: boolean;
}

export const StyledImage = styled.img<StyledImageProps>`
    width: ${(props) => props.image === "default" ? "60px" : "100%"};
    height: ${(props) => props.image === "default" ? "50px" : "100%"};
    padding-left: ${(props) => props.image === "default" ? "15px" : "0px"};
    padding-top: ${(props) => props.image === "default" ? "15px" : "0px"};

    @media only screen and (max-width: 550px) {
        padding-left: ${(props) => props.image === "default" ? "10px" : "0px"};
        padding-top: ${(props) => props.image === "default" ? "10px" : "0px"};
    }
`;

export const StyledImageContainer = styled.div`
    width: 30%;
    max-width: 100px;
    height: 30%;
    max-height: 100px;
    margin: 10px;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.background};
    /*box-shadow: 10px silver;*/
    position: relative;

    .trash {
        padding: 5px;
        border-radius: 100%;
        width: 25px;
        height: 25px;
        cursor: pointer;
        text-align: center;
        line-height: 25px;
        position: absolute;
        right: 0px;
        top: 0px;
        background: red;
        color: white;
        margin-right: -5px;
        margin-top: -5px;
    }

    .deleteIcon {
        color: white;
        cursor: pointer;
    }
`;

export const StyledHeaderImage = styled.div/*<StyledImageProps>*/`
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background: white;
    margin: 10px 4%;
    
    .imgDiv{
        width: 8rem;
        height: 8rem;
        max-width: 250px;
        max-height: 250px;
    }

    .chargeText{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`;

export const StyledPopupDelete = styled.div`
    display: flex;
    width:60vw;
    max-width: 400px;
    flex-direction: column;
    border: 1px solid black;
    background: white;
    border-radius: 20px;
    padding: 10px 10px 34px 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .popupHeader{
        width:100%;
        display: flex;
        flex-direction: row;
        height:60px;
        justify-content: space-between;
    }

    .popupTitle{
        font-weight: bold;
        padding: 4px;
        text-align: center;
    }

    .popupBody{
        padding-bottom: 25px;
        padding-top: 10px;
        text-align: center;
    }

    .popupButtons{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .popupCancel, .popupDelete{
        width: 40%;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-weight: bold;
        border-radius: 11px;
        cursor: pointer
    }

    .popupCancel{
        border: ${({ theme }) => "1px solid " + theme.colors.primary};
    }

    .popupDelete{
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.background};
    }

    .redIcon{
        font-size: 40px;
        color: red;
        padding-left: 20px;
    }

    .close{
        font-size: 20px;
        cursor: pointer;
        margin: 10px;
        color: ${({ theme }) => theme.colors.primary}
    }

    @media only screen and (max-width: 550px)  {
        width: 85vw;
        max-width: 400px;
    }
`

export const StyledPopup = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header div {
    width: 50%;
    text-align: center;
    cursor: pointer;
  }
  
  .popupCancel, .popupDelete {
    width: 40%;
    height: 40px;
    max-width: 60px;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    border-radius: 11px;
    cursor: pointer;
    margin: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid ${({ theme }) => theme.colors.primaryColor};
  }
`;

export const Content = styled.div<ContentProps>`
  width: 100%;
  height: 86vh;
  max-height: calc(70vh - 150px);
  overflow-y: ${(props) => props.overflow ? 'scroll' : 'hidden'};

  .imageDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .imageDiv div {
    max-height: 50%;
    max-width: 50%;
  }

  .imageDiv img {
    max-height: 200px;
    max-width: 200px;
      margin: 1rem 0;
  }

  .imagesContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
  }

  img {
    width: 100%;
    max-width: ${(props) => props.charge ? '' : '100px'};
    height: 100%;
    max-height: ${(props) => props.charge ? '' : '100px'};
    cursor: pointer;
    box-shadow: 10px silver;
  }

  span {
    margin: 1rem 0;
  }
`;

export const StyledGeneralBox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    /*box-shadow: 24px;*/
    width: 85vw;
    max-width: 580px;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    circle{
        color: ${({ theme }) => theme.colors.primaryColor};
    }

    text{
        fill: ${({ theme }) => theme.colors.background};
    }

    span{
        color: ${({ theme }) => theme.colors.primaryColor}};
    }


    .buttons{
        width: 50%;
        max-width: 200px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: ${({ theme }) => theme.colors.primaryColor};
        color: ${({ theme }) => theme.colors.background};
        border-radius: 15px;
        cursor: pointer;
        margin: 15px;
    }

    .close{
        position: absolute;
        margin: 10px;
        top: 0px;
        right: 0px;
    }

    @media only screen and (max-width: 550px)  {
        height: 84vh;
        overflow: auto;
        width: 85vw;
        max-height: 550px;
        padding: 10px;
    }
`;