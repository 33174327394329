import axios, {ResponseType} from "axios";
import {checkAndReplaceDemoCookie, getCookie} from "./utils";
import {apiCall, clientApiCall} from "./helper";
import {ApiResponse, Styles} from "../types/api";

const ADD_PRODUCT = '/api/products/insert';
const ADD_INGREDIENT = '/api/ingredients/insert';
const ADD_CATEGORY = '/api/categories/insert';
const REMOVE_PRODUCT = '/api/products/delete'; //
const REMOVE_INGREDIENT = '/api/ingredients/delete';
const REMOVE_CATEGORY = '/api/categories/delete';
const UPDATE_PRODUCT = '/api/products/update';
const UPDATE_INGREDIENT = '/api/ingredients/update';
const UPDATE_CATEGORY = '/api/categories/update';
const MODIFY_AVAILABLE_PRODUCT = '/api/products/setavailable';
const MODIFY_AVAILABLE_CATEGORY = '/api/categories/setavailable';
const MODIFY_AVAILABLE_INGREDIENT = '/api/ingredients/setavailable';
const MODIFY_ADDABLE_INGREDIENT = '/api/ingredients/setaddable';
const UPDATE_STYLE = '/api/styles/update';
const SEND_COMAND = '/api/orders/sendComand';
const SEND_ORDER = '/api/orders/sendOrder';
const OPEN_TABLE = '/api/orders/openTable';
const CLOSE_TABLE = '/api/orders/closeTable';
const CONNECT_TABLE = '/api/orders/connect';
const CHECK_CONNECTION = '/api/orders/check';
const INFO_TABLE = '/api/orders/infoTable';
const GET_READY = '/api/orders/getReady';
const REGISTER = '/auth/signup'
const CHECKTRIAL = '/auth/getTrial';
const CHECKCODE = '/auth/checkCode';
const TRIALSUBSCRIPTION = '/api/payments/subscriptions/trial';
const SUCCESSTRIAL = 'api/payments/success/';
const CONFIRMACCOUNT = '/auth/checkCode/';
const HISTORY_CLIENT = '/api/orders/getHistoryForClient';
const RESENDCODE = '/auth/resendCode';
const CHANGEPASSWORD = '/auth/updatePassword';
const DELETEIMAGE = '/api/styles/deleteImage/'
const SEND_WAITER = '/api/orders/waiter/sendAndConfirm';
const CHECK_WAITER = '/api/auth/waiter/check';
const LOGOUT = '/auth/logout';
const CANCEL_SUBSCRIPTION = '/api/payments/cancelSubscription';
const RENEW = '/api/payments/renew';
const SEND_MAIL_PASSWORD = '/auth/sendMail'
const RECOVER_PASSWORD = '/auth/recover'
const SEND_WAITER_ORDER = '/api/waiters/sendComand'
const GET_ALL_WAITERS = '/api/waiters/getAllWaiters';
const REGISTRATION_WAITER = '/api/waiters/firstRegistrationWaiter'
const UPDATE_LOGO = '/api/styles/updateLogo';
const CHANGE_ORDER_STATUS = '/api/orders/changeStatus';
const ADD_WAITER = '/api/waiters/addWaiter';
const UPDATE_WAITER = '/api/waiters/updateWaiter';
const REMOVE_WAITER = '/api/waiters/removeWaiter';
const UPDATETABLE = "/api/tables/updateTable";
const ADDTABLE = "/api/tables/addTable";
const REMOVETABLE = "/api/tables/removeTable";

const GETCACHEDATA = "/api/getData"

const message400: string = "INTERNAL ERROR";
const messageError: string = "ERROR";
const message401: string = "PERMISSION ERROR";
const message404: string = "ROUTE NOT FOUND";
const message500: string = "SERVER ERROR";

const GET = 'get'
const POST = 'post'

export const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(config => {
    const token = process.env.REACT_APP_IS_DEMO === "true" ? getDemoSessionId() : getToken();
    if (process.env.REACT_APP_IS_DEMO !== "true" && token === null) {
        throw new Error("No token available");
    }
    config.headers['authorization'] = token;
    config.headers['sessionId'] = getSessionId();
    config.headers['from'] = window.location.href;

    return config;
}, error => {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    return response.data;
}, error => {
    // Posso aggiungere un trattamento degli errori qui
    return Promise.reject(error);
});


export const clientInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

clientInstance.interceptors.request.use(config => {
    const token = process.env.REACT_APP_IS_DEMO === "true" ? getSessionId() : getToken();
    if (process.env.REACT_APP_IS_DEMO !== "true" && token === null) {
        throw new Error("No token available");
    }
    config.headers['authorization'] = getKey();
    return config;
}, error => {
    return Promise.reject(error);
});

clientInstance.interceptors.response.use(response => {
    return response.data;
}, error => {
    // Posso aggiungere un trattamento degli errori qui
    return Promise.reject(error);
});


export const getToken = () => {
    return "Bearer " + localStorage.getItem("token") || null;
}

const getDemoSessionId = () => {
    return getCookie("demo_sessionId") || "0000"
}

const getSessionId = () => {
    return sessionStorage.getItem("sessionId") || "0000"
}

const getKey = () => {
    return localStorage.getItem("clientKey") || ""
}


//export const returnResponseData = (response: ApiResponse<T>) => {
//    if(response.status === 200){
//        if(process.env.REACT_APP_IS_DEMO === "true"){
//            checkAndReplaceDemoCookie(response.data.message)
//        }
//        if(response.data.message==="Token non trovato"){
//            window.location.href = "/login"
//            return null
//        }
//        return response.data;
//    }else{
//        if(response.status === 400){
//            return {status: 400, message: response.data || "error"};
//        }else if(response.status === 401){
//            window.location.href = "/logout"
//        }else if(response.status === 500){
//            return {status: 500, message: "Error"};
//        }else if(response.status === 404){
//            return {status: 404, message: "Error"};
//        }else{
//            return {status: 402, message: "Error"};
//        }
//    }
//}
interface ApiCallInterface {
    method: string;
    url: string;
    data?: any;
    isFormData?: boolean,
    fixed?: boolean
}


export const logout = async(id: number) => {
    return await apiCall({method: GET, url: LOGOUT + "/" + id.toString(), isFormData: false, fixed: true});
}

export const deleteImage = async (id: number) => {
    return await apiCall({method: GET, url: DELETEIMAGE + id, fixed: true})
}

export const changePassword = async (oldPassword: string, newPassword: string) => {
    const data = {
        password: oldPassword,
        newPassword: newPassword
    }
    return await apiCall({method: POST, url: CHANGEPASSWORD, data: data, fixed: true});
}

export const addWaiter = async (name: string, surname: string, email: string) => {
    const data = {
        name: name,
        surname: surname,
        email: email
    }
    return await apiCall({method: POST, url: ADD_WAITER, data: data, fixed: true})
}

export const updateWaiter = async (id: string, name: string, surname: string, email: string) => {
    const data = {
        id: id,
        name: name,
        surname: surname,
        email: email
    }
    return await apiCall({method: POST, url: UPDATE_WAITER, data: data, fixed: true});
}


export const removeWaiter = async (id: number, email: string) => {
    return await apiCall({method: GET, url: REMOVE_WAITER + "/" + id.toString() + "/" + email, fixed: true});
}

export const openTrialSubscription = async () => {
    const data = {
        sessionId: getSessionId()
    }
    return await apiCall({method: POST, url: TRIALSUBSCRIPTION, data: data, fixed: true})
}

export const resendEmail = async() => {
    const data = {
        sessionId: getSessionId()
    }
    return await apiCall({method: POST, url: RESENDCODE, data: data, fixed: true})
}

export const successTrial = async(code: string) => {
    return await apiCall({method: GET, url: SUCCESSTRIAL + code, fixed: true})
}

export const confirmAccount = async(id: number, code: string) => {
    const data = {

    }
    return await apiCall({method: POST, url: CONFIRMACCOUNT + id.toString() + "/" + code, data, fixed: true})
}

export const confirmWaiter = async(code: string, password: string) => {
    const data = {
        token: code,
        password: password
    }
    return await apiCall({method: POST, url: REGISTRATION_WAITER, data: data, fixed: true})
}

export const sendMailPassword = async(email: string) => {
    return await apiCall({method: GET, url: SEND_MAIL_PASSWORD + "/" + email, fixed: true})
}

export const recoverPassword = async(code: string, password: string) => {
    const data = {
        code: code,
        newPassword: password
    }
    return await apiCall({method: POST, url: RECOVER_PASSWORD, data: data, fixed: true})
}

export const checkTrial = async() => {
    return await apiCall({method: GET, url: CHECKTRIAL, fixed: true})
}

export const checkCode = async(id: number, code: string) => {
    return await apiCall({method: GET, url: CHECKCODE + "/" + id + "/" + code, fixed: true})
}

//export const signup = async(formData: FormData) => {
//    return await apiCall({method: POST, url: REGISTER, data: formData, isFormData: true, fixed: true})
//}

export const updateStyle = async(style: Styles) => {
    const data = {
        background: style.background,
        primaryColor: style.primaryColor,
        menuActive: style.menuActive,
        lineMenu: style.lineMenu,
        sliderBackground: style.sliderBackground,
        secondaryColor: style.secondaryColor,
        logo: style.logo,
        template: style.template,
        categoryStyle: style.categoryStyle,
        city: style.city,
        address: style.address,
        phone: style.phone,
        facebook: style.facebook,
        instagram: style.instagram,
        socialX: style.socialX,
        coperto: style.coperto,
        font: style.font,
        email: style.email,
        sessionId: sessionStorage.getItem("sessionId") || "0000"
    }
    return await apiCall({method: POST, url: UPDATE_STYLE, data: data, fixed: true})
}

export const addProduct = async (formData: FormData) => {
    return await apiCall({method: POST, url: ADD_PRODUCT, data: formData, isFormData: true, fixed: true})
}

export const updateLogo = async (formData: FormData) => {
    return await apiCall({method: POST, url: UPDATE_LOGO, data: formData, isFormData: true, fixed: true})
}

export const removeProduct = async (idProduct: number, idCategory: number) => {
    return await apiCall({method: GET, url: REMOVE_PRODUCT + "/" + idProduct, fixed: true})
}

export const updateProduct = async (formData: FormData) => {
    return await apiCall({method: POST, url: UPDATE_PRODUCT, data: formData, isFormData: true, fixed: true})
}

export const addIngredient = async (name: string, price: number, addable: boolean, available: boolean, frozen: boolean, allergens: number[]) => {
    const data = {
        name:name,
        price:price,
        addable:addable,
        available:available,
        frozen: frozen
    }
    return await apiCall({method: POST, url: ADD_INGREDIENT, data: data, fixed: true})
}

export const updateIngredient = async (id: number, name: string, price: number, frozen: boolean, available: boolean, addable: boolean, allergens: number[]) => {
    const data = {
        id:id,
        name:name,
        price:price,
        frozen: frozen,
        available: available,
        addable: addable,
        allergens: allergens
    }
    return await apiCall({method: POST, url: UPDATE_INGREDIENT, data: data, fixed: true})
}

export const removeIngredient = async (idIngredient: number) => {
    return await apiCall({method: GET, url: REMOVE_INGREDIENT + "/" + idIngredient, fixed: true})
}

export const addCategory = async (formData: FormData) => {
    return await apiCall({method: POST, url: ADD_CATEGORY, data: formData, isFormData: true, fixed: true})
}


export const updateCategory = async (formData: FormData) => {
    return await apiCall({method: POST, url: UPDATE_CATEGORY, data: formData, isFormData: true, fixed: true})
}

export const removeCategory = async (idCategory: number) => {
    return await apiCall({method: GET, url: REMOVE_CATEGORY + "/" + idCategory, fixed: true})
}

export const setProductAvailable = async (id: number, available: boolean, idCategory: number) => {
    const data = {
        id: id,
        idCategory: idCategory,
        value: available
    }
    return await apiCall({method: POST, url: MODIFY_AVAILABLE_PRODUCT, data: data, fixed: true})
}

export const setCategoryAvailable = async(id: number, available: boolean) => {
    const data = {
        id: id,
        value: available
    }
    return await apiCall({method: POST, url: MODIFY_AVAILABLE_CATEGORY, data: data, fixed: true})
}

export const setIngredientAvailable = async(id: number, available: boolean) => {
    const data = {
        id: id,
        value: available
    }
    return await apiCall({method: POST, url: MODIFY_AVAILABLE_INGREDIENT, data: data, fixed: true})
}

export const setIngredientAddable = async(id: number, addable: boolean) => {
    const data = {
        id: id,
        value: addable
    }
    return await apiCall({method: POST, url: MODIFY_ADDABLE_INGREDIENT, data: data, fixed: true})
}

/* GESTIONE ORDINI E TAVOLI */

export const openCloseTable = async(open: boolean, idTable: number, number: number) => {
    const data = {
        idTable: idTable
    }
    return await apiCall({method: POST, url: open ? OPEN_TABLE + "/" + number : CLOSE_TABLE, data: data, fixed: true})
}

export const addTable = async(name: string, seats: number) => {
    const data = {
        name: name,
        seats: seats
    }
    return await apiCall({method: POST, url: ADDTABLE, data: data, fixed: true})
}

export const removeTable = async(idTable: number) => {
    return await apiCall({method: GET, url: REMOVETABLE + "/" + idTable.toString(), fixed: true})
}

export const updateTable = async(idTable: number, nameI: string, seatsI: number) => {
    const name = nameI && nameI !== "" ? nameI : "";
    const seats = seatsI && seatsI > 0 ? seatsI : 0;
    const data = {
        idTable: idTable,
        name: name,
        seats: seats
    }
    return await apiCall({method: GET, url: UPDATETABLE, data: data, fixed: true})
}

export const changeOrderStatus = async(status: string, idCommand: string, idTable: number) => {
    const data = {
        idTable: idTable,
        status: status,
        idCommand: idCommand
    }
    return await apiCall({method: POST, url: CHANGE_ORDER_STATUS, data: data, fixed: true})
}

export const getInfoTable = async(idTable: number) => {
    return await apiCall({method: GET, url: INFO_TABLE + "/" + idTable.toString()})
}

export const connectToTable = async(KEY: string, idTable: number, localname: string) => {
    const data = {idTable: idTable, keyTable: KEY, localName: localname}
    return await apiCall({method: POST, url: CONNECT_TABLE, data: data, fixed: true})
}

export const checkConnectionClient = async() => {
    return await clientApiCall({method: GET, url: CHECK_CONNECTION})
}

export const sendOrder = async(products: any) => {
    return await clientApiCall({method: POST, url: SEND_ORDER, data: products, fixed: true})
}

export const sendComand = async() => {
    return await clientApiCall({method: GET, url: SEND_COMAND, fixed: true})
}

export const getReady = async() => {
    return await clientApiCall({method: GET, url: GET_READY})
}

export const getHistoryForClient = async(KEY: string) => {
    return await clientApiCall({method: GET, url: HISTORY_CLIENT})
}

/* waiters api */

export const sendWaiter = async (products: any, table: number) => {
    return await apiCall({method: GET, url: SEND_WAITER + "/" + table.toString(), fixed: true})
}

export const checkWaiter = async () => {
    return await apiCall({method: GET, url: CHECK_WAITER, fixed: true})
}

export const newSubscription = async (months: number, packet: number) => {
    const data = {
        months: months,
        packet: packet
    }
    return await apiCall({method: POST, url: CHECK_WAITER, data: data, fixed: true})
}

export const cancelSubscription = async () => {
    return await apiCall({method: POST, url: CANCEL_SUBSCRIPTION, data: {}, fixed: true})
}

export const renew = async (months: number, packet: number) => {
    const data = {
        months: months,
        packet: packet
    }
    return await apiCall({method: POST, url: RENEW, data: data, fixed: true})
}

export const sendWaiterOrder = async(products: any, idTable: number) => {
    const data = {productToOrders: products, idTable: idTable}
    return await apiCall({method: POST, url: SEND_WAITER_ORDER, data: data, fixed: true})
}

export const getAllWaiters = async() => {
    return await apiCall({method: GET, url: GET_ALL_WAITERS})
}
/**
 *
 * */

export const getCacheData = async (localname: string | null, list: any) => {
    if(!localname)
        return null
    if(list.length === 0)
        return []
    const data = {
        list: list
    }
    return await apiCall({method: POST, url: GETCACHEDATA + "/" + localname, data: data})
}
