import React, {useEffect, useState} from 'react';
import {useDataContext} from "../context/DataContext";
import {useUtilitiesContext} from "../context/UtilitiesContext";
import {useLoginContext} from "../context/LoginContext";
import Loader from "../components/common/Loader";
import {StyledTemplate} from "./styled";

const TemplatePage: React.FC = () => {
    const { loading: dataLoading, styleJson } = useDataContext()
    const { setNavbarTitle, transparentLoading , dashboardNavigateTo } = useUtilitiesContext()

    useEffect(() => {
        setNavbarTitle({title: "Layout", iconRight: {type: "NONE", menu: []}})
    }, []);

    return (
        <>
            {(dataLoading) ? <Loader allPage={true}/> :
                <StyledTemplate>
                    {transparentLoading && <Loader allPage={true} transparent={true}/>}

                    <div className={'containerTemplate'}>
                        <div className={'containerInfoTemplate'}>

                        </div>
                    </div>
                    <div className={'optionsTemplate'}>
                        <div className={"boxBottomContainerTemplate"}>
                            <div className={"titleBottomTemplate"}>Posizioni</div>
                            <div className={"boxBottomTemplate"}></div>
                        </div>
                        <div className={"boxBottomContainerTemplate"}>
                            <div className={"titleBottomTemplate"}>Posizioni</div>
                            <div className={"boxBottomTemplate"}></div>
                        </div>
                    </div>

                </StyledTemplate>}
        </>
    );
};

export default TemplatePage;