import { createTheme } from '@mui/material/styles';
import { Theme } from './theme';

export const createMuiTheme = (theme: Theme) => createTheme({
    palette: {
        primary: {
            main: theme.colors.primary,
        },
        secondary: {
            main: theme.colors.secondary,
        },
        background: {
            default: theme.colors.background,
            paper: theme.colors.secondaryBackground,
        },
        text: {
            primary: theme.colors.text,
            secondary: theme.colors.secondaryText,
        },
        error: {
            main: theme.colors.error,
        },
    },
    typography: {
        fontSize: parseFloat(theme.fontSizes.medium),
        fontWeightLight: theme.fontWeights.normal,
        fontWeightRegular: theme.fontWeights.normal,
        fontWeightMedium: theme.fontWeights.bold,
        fontWeightBold: theme.fontWeights.bold,
    },
    components: {
        MuiIcon:{
          styleOverrides: {
              root: {
                  color: theme.colors.secondaryText,
                  fontSize: '4vh',
              },
          },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: theme.colors.secondary,
                    '&.Mui-checked': {
                        color: theme.colors.primary,
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: theme.colors.primary,
                    },
                },
                track: {
                    backgroundColor: theme.colors.secondaryText,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: theme.colors.secondaryText,
                    fontSize: '24px !important',
                    '&.Mui-checked': {
                        color: 'red', // Adjust color when checked
                    },
                    '& svg': {
                        fontSize: '24px'
                    },
                },
                checked: {
                    fontSize: '24px',
                    color: theme.colors.secondaryText, // Ensure color is red when checked
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    color: theme.colors.text,
                },
                label: {
                    fontSize: '16px', // Adjust label font size
                },
            },
        },
    },
});
