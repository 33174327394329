import React, {useEffect, useRef, useState} from 'react';
import {StyledQRCode} from "./styled";
import Loader from "../components/common/Loader";
import {useDataContext} from "../context/DataContext";
import {useUtilitiesContext} from "../context/UtilitiesContext";
import {requireImg} from "../utils/utils";

const QRCodePage: React.FC = () => {
    const {loading: dataLoading} = useDataContext()
    const { setNavbarTitle, transparentLoading, localname } = useUtilitiesContext()
    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setNavbarTitle({title: "Share", iconRight: {type: "SHARE", menu: []}})
    }, []);

    const handleDownload = () => {
        if (imgRef.current) {
            const imgSrc = imgRef.current.src;
            const link = document.createElement('a');
            link.href = imgSrc;
            link.download = 'QRCode.png'; // Nome del file da scaricare
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <>
            {(dataLoading) ? <Loader allPage={true}/> :
            <StyledQRCode>
                {transparentLoading && <Loader allPage={true} transparent={true}/>}
                <div className={"container"}>
                    <img
                        src={requireImg('QRCode/qrcode.png', localname || "")}
                        ref={imgRef}
                        width="100%"
                        height="100%"
                        alt={"QR Code"}
                        onError={(event) => {
                            const target = event.target as HTMLImageElement;
                            target.src = "/images/noImage.png";
                        }}
                    />
                </div>
                <div className="downloadButton" onClick={handleDownload}>
                    Scarica QR Code
                </div>
            </StyledQRCode>
            }
        </>
    );
};

export default QRCodePage;