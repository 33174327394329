import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";
import Navbar from "../common/Navbar";
import Loader from "../common/Loader";
import {useLoginContext} from "../../context/LoginContext";
import NotificationDisplay from "../common/NotificationDisplay";
import {useUtilitiesContext} from "../../context/UtilitiesContext";

const DashboardLayout: React.FC = () => {
    const { loading: loginLoading } = useLoginContext()

    useEffect(() => {
        try {
            const doc = document.getElementById('root')
            if(doc) {
                doc.style.maxWidth = "500px";
            }
        }catch (error){

        }
    }, []);

    return (
        <>
            {loginLoading ? <Loader allPage={true}/> :
                <div>
                    <Navbar />
                    <Outlet />
                    <NotificationDisplay/>
                </div>
            }
        </>
    );
};

export default DashboardLayout;