import {Box, lighten, Modal, Tab} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useUtilitiesContext } from "../../context/UtilitiesContext";
import { useDataContext } from "../../context/DataContext";
import { handleImageError, requireImg } from "../../utils/utils";
import { deleteImage } from "../../utils/api";
import {Content, StyledGeneralBox, StyledHeaderImage, StyledImageContainer, StyledPopup} from "./ImageChoiceStyled";
import DeleteObj from "./DeleteObj";
import {useTheme} from "styled-components";
import {useNotification} from "../../context/NotificationContext";
import SingleImage from "./SingleImage";
import {Image} from "../../types/types";

interface ImageChoiceProps {
    text: string;
    preview?: string;
    image?: string;
    setPreview: (image: string, isPreview?: boolean, fileLoaded?: File | null) => void;
    selected?: string | null;
}

const ImageChoice: React.FC<ImageChoiceProps> = ({ text, image, setPreview }) => {
    const { addNotification } = useNotification()
    const { setTransparentLoading, localname } = useUtilitiesContext();
    const { imageList, removeImage } = useDataContext();
    const { i18n } = useTranslation();
    const theme = useTheme()

    const [open, setOpen] = useState<boolean>(false);
    const [tab, setTab] = useState<string>('1');
    const fileInputRef = useRef<HTMLInputElement>(null);


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTab('1')
    };

    const previewImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target;

        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = function (e) {
                if (e.target && input.files && input.files[0]) {
                    //TODO: aggiungere immagine alla lista di immagini
                    setPreview(e.target.result as string, true, input?.files[0] || null)
                }
            };

            reader.readAsDataURL(input.files[0]);
        }
    };

    const selectImage = (value: Image) => {
        setPreview(value.img);
        handleClose();
    };

    const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        previewImage(e);
        handleClose();
    };

    const remove = async (id: number, img: string) => {
        setTransparentLoading(true);
        try {
            let tmp = await deleteImage(id);
            if (tmp) {
                removeImage(id, img);
                 addNotification('success', i18n.t("Notifications.confirmed"));
            } else {
                 addNotification('error', i18n.t("Notifications.error"));
            }
        } catch (error) {
            setTransparentLoading(false);
             addNotification('error', i18n.t("Notifications.error"));
        }
        setTransparentLoading(false);
    };

    const handleChange = (e: React.ChangeEvent<{}>, newValue: string) => {
        setTab(newValue);
    };

    return (
        <React.Fragment>

            <StyledHeaderImage onClick={() => handleOpen()}>
                <div className="imgDiv">
                    <img width="100%"
                         height="100%"
                         src={image || "/Photo.png"}
                         onError={(event) => {
                             const target = event.target as HTMLImageElement;
                             target.src = "/images/noImage.png";
                         }}/>
                </div>
                <div className="chargeText">{text}</div>
            </StyledHeaderImage>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <StyledGeneralBox>
                    <StyledPopup>
                        <TabContext value={tab.toString()}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList
                                    aria-label='Tabs'
                                    onChange={handleChange}
                                    sx={{
                                        "& .MuiTabs-indicator": {
                                            backgroundColor: theme.colors.primary,
                                            height: 3,
                                        },
                                        "& .MuiTab-root": {
                                            fontSize: '1rem',
                                            backgroundColor: theme.colors.secondaryBackground, // Colore di sfondo per le schede non selezionate
                                            color: theme.colors.text, // Colore del testo per le schede non selezionate
                                        },
                                        "& .MuiTab-root.Mui-selected": {
                                            backgroundColor: lighten(theme.colors.primary, 0.3) + ' !important', // Colore di sfondo per la scheda selezionata
                                            color: theme.colors.background + ' !important', // Colore del testo per la scheda selezionata
                                        }
                                    }}
                                    centered
                                >
                                    <Tab label={i18n.t('Dashboard.general.myimages')} value={'1'} style={{width: '50%', borderTopLeftRadius: '15px'}} />
                                    <Tab label={i18n.t('Dashboard.general.charge')} value={'2'} style={{width: '50%', borderTopRightRadius: '15px'}} />
                                </TabList>
                            </Box>
                            <TabPanel value={'1'} sx={{padding: '0px !important'}}>
                                <Content overflow={true} charge={false}>
                                    <div className='imagesContent'>
                                        {imageList && imageList.map((value, index) => {
                                            if (value) {
                                                try {
                                                    return (
                                                        <SingleImage selectImage={selectImage} value={{id: value.id, img: value.img}} remove={remove}/>
                                                    );
                                                } catch (error) {
                                                    //console.error(error);
                                                }
                                            }
                                            return null;
                                        })}
                                    </div>
                                </Content>
                            </TabPanel>
                            <TabPanel value={'2'} sx={{padding: '0px !important'}}>
                                <Content overflow={false} charge={true}>
                                    <div style={{height: '100%', margin: '1rem'}}>
                                        <input type="file" ref={fileInputRef} onChange={selectFile}
                                               style={{display: 'none'}}/>
                                        <div className="imageDiv">
                                            <img src="/images/upload.png"
                                                 onClick={() => process.env.REACT_APP_IS_DEMO === "true" ? console.log(i18n.t("Notifications.demo")) : fileInputRef.current?.click()}/>
                                            <span>{i18n.t("Dashboard.general.chargeImg")}</span>
                                        </div>
                                    </div>
                                </Content>
                            </TabPanel>
                        </TabContext>
                    </StyledPopup>
                </StyledGeneralBox>
            </Modal>
        </React.Fragment>
    );
}

export default ImageChoice;
