import {useTranslation} from "react-i18next";
import i18n from 'i18next';
import React from "react";

export const tagsMap = new Map([
    [1, { id: 1, name: i18n.t('Tags.vegetariano'), icon: "tags/vegan.png", description: i18n.t('Dashboard.Tags.vegetarianoDescription') }],
    [2, { id: 2, name: i18n.t('Tags.vegano'), icon: "tags/vegano.png", description: i18n.t('Dashboard.Tags.veganoDescription') }],
    [3, { id: 3, name: i18n.t('Tags.senzaGlutine'), icon: "tags/senzaglutine.png", description: i18n.t('Dashboard.Tags.senzaGlutineDescription') }],
    [4, { id: 4, name: i18n.t('Tags.senzaLatticini'), icon: "tags/nientelatte.png", description: i18n.t('Dashboard.Tags.senzaLatticiniDescription') }],
    [5, { id: 5, name: i18n.t('Tags.senzaNoci'), icon: "tags/senzanoci.png", description: i18n.t('Dashboard.Tags.senzaNociDescription') }],
    [6, { id: 6, name: i18n.t('Tags.biologico'), icon: "tags/biologico.png", description: i18n.t('Dashboard.Tags.biologicoDescription') }],
    [11, { id: 11, name: i18n.t('Tags.senzaZuccheroAggiunto'), icon: "tags/senzazucchero.png", description: i18n.t('Dashboard.Tags.noZuccheroAggDescription') }],
    [13, { id: 13, name: i18n.t('Tags.senzaAlcol'), icon: "tags/noalcool.png", description: i18n.t('Dashboard.Tags.noAlcolDescription') }],
]);

export const allergensMap = new Map([
    [1, { id: 1, name: i18n.t('Allergens.Glutine') }],
    [2, { id: 2, name: i18n.t('Allergens.Crostacei') }],
    [3, { id: 3, name: i18n.t('Allergens.Uova') }],
    [4, { id: 4, name: i18n.t('Allergens.Pesce') }],
    [5, { id: 5, name: i18n.t('Allergens.Arachidi') }],
    [6, { id: 6, name: i18n.t('Allergens.Soia') }],
    [7, { id: 7, name: i18n.t('Allergens.Latte') }],
    [8, { id: 8, name: i18n.t('Allergens.Frutta a guscio') }],
    [9, { id: 9, name: i18n.t('Allergens.Sedano') }],
    [10, { id: 10, name: i18n.t('Allergens.Senape') }],
    [11, { id: 11, name: i18n.t('Allergens.Sesamo') }],
    [12, { id: 12, name: i18n.t('Allergens.Anidride Solforosa') }],
    [13, { id: 13, name: i18n.t('Allergens.Lupini') }],
    [14, { id: 14, name: i18n.t('Allergens.Molluschi') }]
]);

export const changeLanguage = (value: string): void => {
    localStorage.setItem("language", value);
    i18n.changeLanguage(value);
}

export const hexToRgb = (hex: string): { r: number, g: number, b: number } => {
    // Rimuove il carattere # se presente
    hex = hex.replace(/^#/, '');

    // Converte il valore esadecimale in valori RGB
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Restituisce i valori RGB in un oggetto
    return { r, g, b };
}

const loadIngredientsMinus = (value: any): string | null => {
    let tmp = ""
    if(value.ingredientsMinus?.length > 0){
        value.ingredientsMinus.map((ing: any, index: number) => {
            tmp += index === 0 ? "- " + ing.name : ", " + ing.name;
        })
    }
    return tmp === "" ? null : tmp;
}

const loadIngredientsPlus = (value: any): string | null => {
    let tmp = ""
    if(value.ingredientsPlus?.length > 0){
        value.ingredientsPlus.map((ing: any, index: number) => {
            tmp += index === 0 ? "+ " + ing.name + "(€" + Number(ing.price).toFixed(2) + ")" : ", " + ing.name + "(€" + Number(ing.price).toFixed(2) + ")";
        })
    }
    return tmp === "" ? null : tmp;
}

export const print = (orderOpened: any, nameTable: string): void => {

    let orderDetails = orderOpened.productToOrders && orderOpened.productToOrders.map((value: any, index: number) => {
        const ingPlus = loadIngredientsPlus(value) || [];
        const ingMinus = loadIngredientsMinus(value) || [];
        return (`
          <div>
              <div>${value.category}</div>
              <div>${value.quantity} x ${value.name}</div>
              ${ingMinus && `<div>${ingMinus}</div>`}
              ${ingPlus && `<div>${ingPlus}</div>`}
              <div>€ ${Number(value.price).toFixed(2)}</div>
              <div>--------------</div>
          </div>
      `);
    }).join('');

    let divContents = `
      <div style={{"overflowY: 'scroll', width:'100%', textAlign: 'center', margin: '12px'"}}>
          Prodotti: ${orderDetails}
      </div>
  `;
    let a = window.open('', '', 'height=500, width=500');
    if(a) {
        a.document.write('<html>');
        a.document.write('<body > <h1>Tavolo ' + nameTable + '<h1>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }
}

export const requireImg = (img: string, localname: string): string => {
    if (img && img !== "null" && img !== null && img !== undefined && img !== "undefined" && img !== "noImage") {
        return `${process.env.REACT_APP_IMAGES_PATH}/${localname}/${img.startsWith("/") ? img.substring(1) : img}`
        //return `${process.env.REACT_APP_SERVER_BASE}/img/menu/${process.env.REACT_APP_SERVER_VERSION}/${localname}/${img.startsWith("/") ? img.substring(1) : img}`;
    }
    return "/noImage.png"; // Immagine di fallback se img non è valida
};

//export const requireGeneralImg = (img: string): string => {
//    let tmp = "/noImage.png";
//    if(img && img !== "null"){
//        return `${process.env.REACT_APP_SERVER_BASE}/img/menu/${process.env.REACT_APP_SERVER_VERSION}/${img.startsWith("/") ? img.substring(1) : img}`;
//    }
//    return tmp;
//}

export const handleImageError = (event: any): void => {
    if (event.target.src !== "/noImage.png") {
        event.target.src = "/noImage.png"; // Carica un'immagine di fallback in caso di errore
    }
};

export const getDataFromTimestamp = (timestamp: number): string => {

    const date = new Date(timestamp);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year.toString().padStart(4, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

}

export const getTimeFromTimestamp = (timestamp: number): string => {

    const date = new Date(timestamp);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

}

export const getLanguage = (): string => {
    // Ottieni la lingua preferita dal browser
    const language = navigator.language || (navigator as any).userLanguage;

    // Estrai solo i primi due caratteri per ottenere il codice della lingua
    return language.substring(0, 2);
}

export const toCamelCase = (str: string): string => {
    return str
        .split(' ')
        .map((word, index) => {
            if (index === 0) {
                // Prima parola in minuscolo
                return word.toLowerCase();
            }
            // Parole successive con la prima lettera maiuscola
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
}


export const setCookie = (name: string, value: any, days: number): void => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

// Funzione per recuperare il valore di un cookie dato il suo nome
export const getCookie = (name: string): string | null => {
    let nameEQ = name + "=";
    let cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) === 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    return null;
}

// Funzione per eliminare un cookie dato il suo nome
export const deleteCookie = (name: string): void => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
}

export const checkAndReplaceDemoCookie = (newId: number): void => {
    const old = getCookie("demo_sessionId");
    if(old && (old !== newId.toString())){
        deleteCookie("demo_sessionId");
        setCookie("demo_sessionId", newId, 2);
    }
}


export const handleChangePrice = (e: any, setDisplayValue: React.Dispatch<React.SetStateAction<string>>, setPrice: React.Dispatch<React.SetStateAction<number>>): void => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9,.]/g, '').replace(',', '.');
    let commaCount = inputValue.split('.').length - 1;
    if (commaCount > 1) {
        inputValue = inputValue.slice(0, -1);
    }
    const decimalIndex = inputValue.indexOf('.');
    if (decimalIndex !== -1) {
        const decimalPart = inputValue.substring(decimalIndex + 1);
        if (decimalPart.length > 2) {
            inputValue = inputValue.slice(0, decimalIndex + 3);
        }
    }
    if(inputValue==="."){
        inputValue=""
    }

    setDisplayValue(inputValue);
    setPrice(Number(inputValue));
};

export const generateSessionId = (): string => {
    const length = 6;
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let token = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        token += characters.charAt(randomIndex);
    }
    sessionStorage.setItem("sessionId", token);
    return token;
}

export const getSessionId = (): string | null => {
    return sessionStorage.getItem("sessionId") || null;
}

export const getPageFromId = (id: number): string => {
    switch (id){
        case 0:
            return "Dashboard";
        case 1:
            return "Product"
        case 2:
            return "Product/Ingredients"
        case 3:
            return "AddIngredient"
        case 4:
            return "AddProduct"
        case 5:
            return "AddCategory"
        case 6:
            return "Product/Allergens"
        case 7:
            return "Product/Tags"
        case 8:
            return "Product/Allergens/Add"
        case 9:
            return "Product/Ingredients/Add"
        case 10:
            return "Product/Tags/Add"
        case 11:
            return "Profile"
        case 12:
            return "QRCode"
        case 13:
            return "Orders"
        case 14:
            return "Tables"
        case 15:
            return "AddTable"
        case 16:
            return "Table"
        case 17:
            return "Info"
        case 18:
            return "BillingInfo"
        case 19:
            return "Category"
        case 20:
            return "Ingredient"
        case 21:
            return "Summary"
        case 22:
            return "ChangePassword"
        case 23:
            return "Template"
        case 90:
            return "Logout"
    }
    return "Categories";
}

export const setToken = (token: string | null) => {
    if(!token)
        localStorage.removeItem("token");
    else
        localStorage.setItem("token", token);
}