import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface LoaderProps {
    transparent?: boolean;
    allPage?: boolean;
    color?: string;
}

const Loader: React.FC<LoaderProps> = (props) => {
    //const utilities = (React.useContext(UtilitiesContext)).utilitiesContextValue

    return (
        <>
            {props.transparent ?
                <Box sx={{ display: 'flex', zIndex: '9999', position: 'fixed', left: '0', top: '0', height: "100vh", width: "100%", alignItems:"center", justifyContent:"center", background: 'rgb(0,0,0,0.7)' }}>
                    <CircularProgress sx={{color: "white"}} />
                </Box>
                :
                <Box sx={{ display: 'flex', zIndex: '9999', height: props.allPage ? "100vh" : "100%", width: "100%", alignItems:"center", justifyContent:"center", background:""+props.color || "white" }}>
                    <CircularProgress />
                </Box>
            }
        </>
    );
};

export default Loader;