import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Notification, NotificationContextType, NotificationType } from '../types/context';

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};

interface NotificationProviderProps {
    children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const addNotification = (type: NotificationType, message: string) => {
        setNotifications(prevNotifications => [...prevNotifications, { type, message }]);
    };

    const removeNotification = (index: number) => {
        setNotifications(prevNotifications => prevNotifications.filter((_, i) => i !== index));
    };

    // Function to remove a notification after a delay
    const addNotificationWithAutoDismiss = (type: NotificationType, message: string) => {
        const index = notifications.length;
        addNotification(type, message);

        setTimeout(() => {
            removeNotification(index);
        }, 6000); // 6000 milliseconds = 6 seconds
    };

    return (
        <NotificationContext.Provider value={{ notifications, addNotification: addNotificationWithAutoDismiss, removeNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};