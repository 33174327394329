export interface Theme {
    colors: {
        primary: string;
        secondary: string,
        background: string;
        secondaryBackground: string;
        text: string;
        secondaryText: string,
        silver: string,
        error: string;
    };
    fontSizes: {
        small: string,
        medium: string,
        big: string
    };
    fontWeights:{
        normal: string,
        bold: string,
    }
}

export const lightTheme: Theme = {
    colors: {
        primary: '#FF613C',
        secondary: '#2B2F37',
        background: 'white',
        secondaryBackground: '#F3F3F3',
        text: '#333',
        secondaryText: 'black',
        silver: 'silver',
        error: '#e74c3c',
    },
    fontSizes: {
        small: '0.875rem',
        medium: '0.875rem',
        big: '0.875rem',
    },
    fontWeights: {
        normal: 'normal',
        bold: 'bold',
    }
};

export const darkTheme: Theme = {
    colors: {
        primary: '#FF613C',
        secondary: '#2B2F37',
        background: 'white',
        secondaryBackground: '#F3F3F3',
        text: '#333',
        secondaryText: 'black',
        silver: 'silver',
        error: '#e74c3c',
    },
    fontSizes: {
        small: '0.875rem',
        medium: '0.875rem',
        big: '0.875rem',
    },
    fontWeights: {
        normal: 'normal',
        bold: 'bold',
    }
};
