import {requireImg} from "../../utils/utils";
import DeleteObj from "./DeleteObj";
import {StyledImageContainer} from "./ImageChoiceStyled";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useUtilitiesContext} from "../../context/UtilitiesContext";
import {Image} from "../../types/types";

interface SingleImageProps {
    selectImage: (value: Image) => void;
    value: Image;
    remove: (id: number, img: string) => void
}

const SingleImage: React.FC<SingleImageProps> = ({ value, selectImage, remove }) => {
    const { i18n } = useTranslation();
    const { localname } = useUtilitiesContext()
    const [visible, setVisible] = useState<boolean>(true);

    const hide = () => {
        setVisible(false)
    }

    const goRemove = (img: string, id?: number) => {
        if(id)
            remove(id, img)
    }

    return (
        visible ?
        <StyledImageContainer>
            <img src={requireImg(value.img, localname || "")}
                 onClick={() => selectImage(value)}
                 alt={value.img}
                 onError={hide}/>
            <div className="deleteIcon" style={{fontSize: "15px"}}>
                <div className='trash'>
                    <DeleteObj
                        onlyIcon={true}
                        remove={() => process.env.REACT_APP_IS_DEMO === "true" ? console.log('') : goRemove(value.img, value.id)}
                        text={"Rimuovi immagine"}
                        messageTitle={i18n.t("Dashboard.popups.deleteImgTitle")}
                        messageBody={i18n.t("Dashboard.popups.deleteImgBody")}/>
                </div>
            </div>
        </StyledImageContainer>
            : <></>
    )
}

export default SingleImage