import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import {LoginContextType} from "../types/context";
import {setToken} from "../utils/utils";
import {changePassword, getToken} from "../utils/api";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {User} from "../types/types";
import {loginResponse} from "../types/api";

const LoginContext = createContext<LoginContextType | undefined>(undefined);

export const useLoginContext = () => {
    const context = useContext(LoginContext);
    if (!context) {
        throw new Error('useLoginContext must be used within a LoginProvider');
    }
    return context;
};

export const LoginProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [transparentLoading, setTransparentLoading] = useState<boolean>(false)
    const [authorized, setAuthorized] = useState<boolean>(false)
    const [user, setUser] = useState<User | null>(null);
    const { localname } = useParams()

    const navigate = useNavigate()

    useEffect(() => {
        _check()
    }, [])

    const _check = async () => {
        const token: string | null = getToken()
        if(!token){
            setAuthorized(false)
            setUser(null)
            if(!window.location.href.includes("/register")) {
                navigate("/login")
            }
            setLoading(false)
            return
        }

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/check', {
                authorization: token,
                localName: localname || localStorage.getItem("localname"),
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token,
                }
            });

            if (response.data) {
                const userTmp: User = response.data
                setUser(userTmp)
                setAuthorized(true)
                if(response.data.status !== "NOT_CONFIRMED" && (window.location.href.includes("/login") || window.location.href === process.env.REACT_APP_MY_URL + "/" || window.location.href === process.env.REACT_APP_MY_URL + ""))
                    navigate("/" + response.data.localname + "/Dashboard" )
                setLoading(false)
                return true;
            } else {
                //if(response.status === 432){
                //    navigate("/"+(window.location.href === process.env.REACT_APP_MY_URL + "/login") ? localStorage.getItem("localname") : params.localname+"/supscription-with-trial");
                //}else if(response.status === 433){
                //    navigate("/emailNotConfirmed/");
                //} TODO gestire i diversi tipi di accessi
                setAuthorized(false)
                setUser(null)
                if(!window.location.href.includes("/register")) {
                    navigate("/login")
                }
                setLoading(false)
            }
        } catch (error) {
            if(!window.location.href.includes("/register")) {
                navigate("/login")
            }
            setLoading(false)
        }

    }

    const login = async (email: string, password: string) => {
        setTransparentLoading(true)
        try {
            const response: loginResponse = await axios.post(process.env.REACT_APP_API_URL + '/auth/signin', {
                username: email,
                password: password
            });

            if (response.status === 200 || response.status === 432 || response.status === 433 || response.status === 434) {
                if(!authorized)
                    setAuthorized(true);
                localStorage.setItem('token', response.data.accessToken)
                localStorage.setItem('localname', response.data.localname)
                setToken(response.data.accessToken)
                //setUser(response.data)
                setLoading(false)
                navigate("/" + response.data.localname + "/Dashboard")
                return null
            }else{
                if(authorized) {
                    setAuthorized(false);
                    setUser(null);
                }
                setToken(null)
                //addNotification('error', i18n.t("Notifications.errorData"))
                localStorage.removeItem("localname");
                setTransparentLoading(false)
                return "Errore credenziali";
            }
        } catch (error) {
            // TODO: gestione casistiche accesso (account da confermare, abbonamento scaduto)
            //if (error.response.status === 200 || error.response.status === 432 || error.response.status === 433 || error.response.status === 434) {
            //    if(!isAuthenticated)
            //        setIsAuthenticated(true);
            //    localStorage.setItem('token', error.response.data.accessToken)
            //    localStorage.setItem('localname', error.response.data.localname)
            //    setToken(error.response.data.accessToken)
            //    setLoading(false)
            //    return true
            //}else{
            //    setLoading(false)
            //    return false;
            //}
        }
        setTransparentLoading(false)
        return "Errore";
    }

    const changePasswordFunc = async (oldPassword: string, newPassword: string ) => {
        let msg: string = "ERROR"
        try{
            const tmp: any = await changePassword(oldPassword, newPassword);
            if(tmp){
                msg = "SUCCESS"
            }
        }catch (error){
            console.log(error)
        }
        return msg;
    }

    const logout = () => {
        setToken(null)
        setAuthorized(false)
        setUser(null)
    };

    const register = async (formData: FormData) => {
        const error = "Errore";
        try{
            const tmp = await axios.post(process.env.REACT_APP_API_URL + '/auth/signup', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if(tmp){
                return "Success"
            }
        }catch (error){

        }
        return error;
    }

    return (
        <LoginContext.Provider value={{ login, logout, changePasswordFunc, register, transparentLoading, loading, user }}>
            {children}
        </LoginContext.Provider>
    );
};