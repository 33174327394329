import React, {useMemo, useState} from 'react';
import { requireImg } from "../../../utils/utils";
import { useUtilitiesContext } from "../../../context/UtilitiesContext";
import { useTranslation } from "react-i18next";
import { StyledCategoryRow } from "../styled";
import { Category, Ingredient } from "../../../types/types";
import { useDataContext } from "../../../context/DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface CategoryRowProps {
    category: Category;
    search: string;
}

const CategoryRow: React.FC<CategoryRowProps> = ({ category, search }) => {
    const { i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const { localname, dashboardNavigateTo } = useUtilitiesContext();
    const { productsByCategory, productsMap, ingredientsMap } = useDataContext();

    // Filtra i prodotti in base alla ricerca
    const filteredProducts = useMemo(() => {
        return productsByCategory.get(category.id)?.filter((idProduct: number) => {
            const product = productsMap.get(idProduct);
            return product && (search === "" || product.name.toLowerCase().includes(search.toLowerCase()));
        });
    }, [category.id, search, productsByCategory, productsMap]);

    // Controlla se ci sono prodotti filtrati
    const hasProducts = filteredProducts && filteredProducts.length > 0;

    // Verifica se la categoria deve essere mostrata con un messaggio di vuoto
    const shouldDisplayCategory = search !== "" ? hasProducts : true;

    return (
        <StyledCategoryRow>
            {shouldDisplayCategory ? (
                <>
                    <div className="categoryRow" onClick={() => setOpen(!open)}>
                        {category.name}
                        <div className="iconUpDown">
                            <FontAwesomeIcon icon={open ? faChevronDown : faChevronUp} />
                        </div>
                    </div>

                    {open && hasProducts ? filteredProducts.map((idProduct: number) => {
                        const product = productsMap.get(idProduct);

                        if (product) {
                            return (
                                <div className={"box"} key={idProduct}>
                                    <div className="boxRow">
                                        <div className="boxDiv">
                                            <img
                                                src={requireImg(product.img || 'noImage', localname || "")}
                                                width="100%"
                                                height="100%"
                                                alt={product?.img || ""}
                                                onError={(event) => {
                                                    const target = event.target as HTMLImageElement;
                                                    target.src = "/images/noImage.png";
                                                }}
                                            />
                                        </div>
                                        <div className="productInfo"
                                             onClick={() => product?.id && dashboardNavigateTo(1, product?.id.toString())}>
                                            <div>
                                                <div className="productName">{product.name}</div>
                                                <div className="ingredientList">
                                                    {product?.ingredients
                                                        ?.map(id => ingredientsMap.get(id))
                                                        .filter((ing): ing is Ingredient => ing !== undefined)
                                                        .map(ing => ing.name)
                                                        .join(', ')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="price">€ {Number(product.price).toFixed(2)}</div>
                                </div>
                            );
                        }
                        return null;
                    }) : (
                        open && search === "" && <div className="noProducts">Categoria Vuota</div>
                    )}
                </>
            ) : null}
        </StyledCategoryRow>
    );
};

export default CategoryRow;
