import React, { useEffect } from 'react';
import { useNotification } from '../../context/NotificationContext';
import { StyledNotification } from "./styled";

const NotificationDisplay: React.FC = () => {
    const { notifications, removeNotification } = useNotification();

    useEffect(() => {
        // Set up timers for all notifications
        notifications.forEach((_, index) => {
            setTimeout(() => removeNotification(index), 6000); // 6000 milliseconds = 6 seconds
        });
    }, [notifications, removeNotification]);

    return (
        <StyledNotification>
            {notifications.map((notification, index) => (
                <div key={index} className={`notification ${notification.type}`}>
                    <span>{notification.message}</span>
                    {/* The dismiss button is not needed now */}
                </div>
            ))}
        </StyledNotification>
    );
};

export default NotificationDisplay;