import React, {useEffect, useState} from 'react';
import {useDataContext} from "../context/DataContext";
import {useUtilitiesContext} from "../context/UtilitiesContext";
import {useLoginContext} from "../context/LoginContext";
import Loader from "../components/common/Loader";

const BillingPage: React.FC = () => {
    const { loading: dataLoading, styleJson } = useDataContext()
    const { setNavbarTitle , transparentLoading, dashboardNavigateTo } = useUtilitiesContext()
    const { user } = useLoginContext()

    useEffect(() => {
        setNavbarTitle({back: {back: true, custom: false}, title: "Abbonamento", iconRight: {type: "NONE", menu: []}})
    }, []);

    return (
        <>
            {(dataLoading) ? <Loader allPage={true}/> :
        <div className="login-container">
            {transparentLoading && <Loader allPage={true} transparent={true}/>}
        </div>}
            </>
    );
};

export default BillingPage;