import * as React from 'react';
import {StyledSearch} from "./styled";
import {useTranslation} from "react-i18next";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface SearchProps {
    onChange: (value: string) => void;
    search: string
}

const Search: React.FC<SearchProps> = ({ search, onChange }) => {
    const { i18n } = useTranslation();

    return (
        <StyledSearch>
            <div className="icon">
                <FontAwesomeIcon icon={faSearch}/>
            </div>
            <input type="text" value={search} placeholder="Cerca" onChange={(e) => onChange(e.target.value)} />
        </StyledSearch>
    )
}

export default Search;

