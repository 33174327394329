import {Category, Ingredient, Product} from "../../../types/types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useUtilitiesContext} from "../../../context/UtilitiesContext";
import {useDataContext} from "../../../context/DataContext";
import {StyledCategoryRow, StyledSummaryRow} from "../styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {requireImg} from "../../../utils/utils";
import {StyledPageList} from "../../../pages/styled";

interface SummaryRowProps {
    title: String
    type: String
    list: Category[] | Product[] | Ingredient[]
}

const SummaryRow: React.FC<SummaryRowProps> = ({title, list, type}) => {
    const {i18n} = useTranslation();
    const [open, setOpen] = useState<boolean>(false)
    const {dashboardNavigateTo, localname} = useUtilitiesContext()

    useEffect(() => {
        setOpen(false)
    }, []);

    const navigateTo = (idObject: number) => {
        let idPage = 19;
        switch(type){
            case "INGREDIENTS":
                idPage =20;
                break;
            case "CATEGORIES":
                idPage = 19;
                break;
            case "PRODUCTS":
                idPage = 1;
                break;
        }
        dashboardNavigateTo(idPage, idObject.toString())
    }

    return (
        <StyledSummaryRow>
            {list && list.length > 0 && <>
            <div className={"title"} onClick={() => setOpen(!open)}>
                <span>{title}</span>
                <span className={'iconRight'}><FontAwesomeIcon icon={open ? faChevronUp : faChevronDown}/></span>
            </div>
            {open && list && list.map((obj, index) => {
                if(type === "CATEGORIES" && obj.id === 1){
                    return <></>
                }
                return (
                        <div className={"box"} key={index} onClick={() => obj.id && navigateTo(obj.id)}>
                            <div className="boxDiv">
                                {type !== "INGREDIENTS" &&
                                    <img
                                        src={requireImg((obj as Category | Product).img || 'noImage', localname || "")}
                                        width="100%"
                                        height="100%"
                                        alt={(obj as Category | Product).img || "noImage"}
                                        onError={(event) => {
                                            const target = event.target as HTMLImageElement;
                                            target.src = "/images/noImage.png";
                                        }}
                                    />
                                }
                            </div>
                            <div className={'name'}>{obj.name}</div>
                            <div className={'iconRight'}><FontAwesomeIcon icon={faAngleRight}/></div>
                        </div>
                    )
                }
            )
            }
            </>}
        </StyledSummaryRow>
    )
};

export default SummaryRow;