import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MenuItem, Modal} from "@mui/material";
import {DeleteGeneralContainer} from "./styled";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {StyledPopup, StyledPopupDelete} from "./ImageChoiceStyled";
import {faTriangleExclamation, faCircleXmark, faTrash} from "@fortawesome/free-solid-svg-icons";

interface DeleteObjProps {
    remove: () => void | Promise<void>;
    messageTitle: string;
    messageBody: string;
    text: string;
    onlyIcon?: boolean
}

const DeleteObj: React.FC<DeleteObjProps> = ({remove, text, messageTitle, messageBody, onlyIcon}) => {
    const { i18n } = useTranslation();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const deleteObj = () => {
        remove()
        handleClose();
    }

    return (
        <DeleteGeneralContainer>
            {onlyIcon ?
                <div className={"deleteIcon"} onClick={handleOpen}><FontAwesomeIcon icon={faTrash}/></div>
                :
                <div className={"delete"} onClick={handleOpen}>{text}</div>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <StyledPopupDelete>
                    <div className='popupHeader'>
                        <div></div>
                        <div className={"redIcon"}><FontAwesomeIcon icon={faTriangleExclamation}/></div>
                        <div><FontAwesomeIcon className={"close"} onClick={handleClose} icon={faCircleXmark}/></div>
                    </div>
                    <div className='popupTitle'>
                        {messageTitle}
                    </div>
                    <div className='popupBody'>
                        {messageBody}
                    </div>
                    <div className='popupButtons'>
                        <div className='popupCancel' onClick={handleClose}>{i18n.t('Dashboard.general.cancel')}</div>
                        <div className='popupDelete' onClick={deleteObj}>{i18n.t('Dashboard.general.delete')}</div>
                    </div>
                </StyledPopupDelete>

            </Modal>
        </DeleteGeneralContainer>
    )
};

export default DeleteObj;