import React, {useState, useContext, useEffect} from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../context/ThemeContext';
import {Theme} from "../theme/theme";
import {useLoginContext} from "../context/LoginContext";
import Loader from "../components/common/Loader";

interface LoginFormValues {
    email: string;
    password: string;
}

const LoginPage: React.FC = () => {
    const { login, loading, transparentLoading } = useLoginContext();
    const { theme } = useContext(ThemeContext) || { theme: {} };
    const [formValues, setFormValues] = useState<LoginFormValues>({ email: '', password: '' });
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        try {
            const doc = document.getElementById('root')
            if(doc) {
                doc.style.maxWidth = "100%";
            }
        }catch (error){

        }
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { email, password } = formValues;

        if (!email || !password) {
            setError('Compila entrambi i campi');
            return;
        }
        try {
            const tmp = await login(email, password);
            setError(tmp);
        } catch (error) {
            setError('Login non riuscito.');
        }
    };

    return (
        <>
            {loading ? <Loader allPage={true} /> :
                <LoginContainer theme={theme}>
                    {transparentLoading && <Loader allPage={true} transparent={true} /> }
                    <Logo src="/images/WeitLogo.png" alt="WeitLogo" />
                    <h2 style={{margin: '1.5rem 0'}}>Login</h2>
                    <LoginForm onSubmit={handleSubmit}>
                        <FormGroup>
                            <label htmlFor="email">Email:</label>
                            <Input
                                type="text"
                                id="email"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="password">Password:</label>
                            <Input
                                type="password"
                                id="password"
                                name="password"
                                value={formValues.password}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                        <SubmitButton type="submit">Login</SubmitButton>
                    </LoginForm>
                </LoginContainer>
            }
        </>
    );
};

const LoginContainer = styled.div<{ theme: Theme }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #f0f4f8, #d9e2ec); /* Gradient background */
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    padding: 20px;
`;

const Logo = styled.img`
    width: 150px;
`;

const LoginForm = styled.form`
    width: 100%;
    max-width: 400px; /* Adjust max-width to control form size */
    padding: 30px;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3)
`;

const FormGroup = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Input = styled.input`
    width: 100%;
    padding: 9px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;

    &:focus {
        border-color: ${({ theme }) => theme.colors.primary};
        outline: none;
    }
`;

const SubmitButton = styled.button`
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary + 'b3'};
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }
`;

const ErrorMessage = styled.p`
    color: ${({ theme }) => theme.colors.error};
    margin-bottom: 20px;
    font-size: 14px;
`;

export default LoginPage;
