import React, {useEffect, useState} from 'react';
import {useDataContext} from "../context/DataContext";
import Loader from "../components/common/Loader";
import {StyledPage} from "./styled";
import {requireImg, tagsMap} from "../utils/utils";
import {useUtilitiesContext} from "../context/UtilitiesContext";
import {Category, ProductFormData} from "../types/types";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import {addCategory, removeCategory, updateCategory} from "../utils/api";
import ImageChoice from "../components/common/ImageChoice";
import DeleteObj from "../components/common/DeleteObj";
import {Switch} from "@mui/material";
import {CategoryFormData} from "../types/context";
import {useCategoryContext} from "../context/CategoryContext";
import {useNotification} from "../context/NotificationContext";

interface CategoryPageProps {
    isUpdate?: boolean
}

const CategoryPage: React.FC<CategoryPageProps> = ({isUpdate}) => {
    const { loading: dataLoading, productsMap, productsByCategory, categoriesOperation, categoriesMap} = useDataContext()
    const {localname, goBack, dashboardNavigateTo, transparentLoading, setNavbarTitle, setTransparentLoading} = useUtilitiesContext()
    const {idCategory} = useParams()
    const {loadTmpCategoryFormData, setTmpIsNew, categoryTmpFormData, manageProducts, isNew, loadTmpCategory, categorySelected} = useCategoryContext()
    const { addNotification } = useNotification()

    let category = isUpdate ? categoriesMap.get(Number(idCategory)) : null;

    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [img, setImg] = useState<string>('')
    const [file, setFile] = useState<File | null>(null)
    const [available, setAvailable] = useState<boolean>(true)
    const [preview, setPreview] = useState<string | null>('')
    const [selectedProducts, setSelectedProducts] = useState<number[]>([])

    useEffect(() => {
        if(!isUpdate)
            setNavbarTitle({title: "Aggiungi Categoria", iconRight: {type: "NONE", menu: []}})
        reload()
    }, []);

    const updateImage = (image: string, isPreview?: boolean, fileLoaded?: File | null) => {
        setPreview(isPreview ? image : null)
        setImg(isPreview ? '' : image)
        setFile(fileLoaded ? fileLoaded : null);
    }

    const handleClose = () => {
        const category: Category | undefined = categoriesMap.get(Number(idCategory));
        setName(category?.name || '')
        setImg(category?.img || '')
        setFile(null)
    };

    const reload = () => {
        if(!dataLoading) {
            if (isUpdate) {
                category = categoriesMap.get(Number(idCategory));
                let fromContext = true;
                if (category) {
                    if (!categorySelected || categorySelected.id !== category.id) {
                        loadTmpCategory(category)
                        fromContext = false;
                    }
                    setNavbarTitle({title: category.name || "", iconRight: {type: "NONE", menu: []}})
                    if(categoryTmpFormData) {
                        setName(categoryTmpFormData?.name);
                        setDescription(categoryTmpFormData?.description);
                        setImg(categoryTmpFormData?.img || "noImage.png");
                        setAvailable(categoryTmpFormData.available);
                        setFile(categoryTmpFormData.file)
                        setPreview(categoryTmpFormData.preview)
                    }else{
                        if(category) {
                            setName(category?.name);
                            setDescription(category?.description);
                            setImg(category?.img || "noImage.png");
                            setAvailable(category.available);
                            setSelectedProducts(fromContext ? [...manageProducts.existing, ...manageProducts.added].filter((element) => !manageProducts.removed.includes(element)) : productsByCategory.get(Number(idCategory)));
                        }
                    }
                }
            }else{
                if(!isNew)
                    setTmpIsNew()
                else{
                    if(categoryTmpFormData) {
                        setName(categoryTmpFormData?.name);
                        setDescription(categoryTmpFormData?.description);
                        setImg(categoryTmpFormData?.img || "noImage.png");
                        setFile(categoryTmpFormData.file)
                        setPreview(categoryTmpFormData.preview)
                        setAvailable(categoryTmpFormData.available);
                    }
                }
            }
        }
    }

    const save = async () => {
        if(name === null || name === ""){
            addNotification('error', i18n.t("Notifications.errorName"))
            return
        }
        setTransparentLoading(true);
        const formData = new FormData();
        formData.append('name', (name.charAt(0).toUpperCase() + name.slice(1)));
        formData.append('img', img)
        selectedProducts.forEach((product: number) => {
            formData.append('products[]', product.toString());
        });

        if (file !== null && file !== undefined) {
            formData.append('file', file);
        }
        formData.append('available', available.toString())
        formData.append('description', description)
        try {
            const tmp: any = await addCategory(formData);
            if (tmp) {
                categoriesOperation({operation: "ADD"}, {
                    id: tmp.id,
                    name: (name.charAt(0).toUpperCase() + name.slice(1)),
                    img: tmp.img || null,
                    available: available,
                    description: description
                })
                addNotification('success', i18n.t("Notifications.confirmed"));
                setTransparentLoading(false);
                goBack()
            } else {
                //notifica di errore
                addNotification('error', i18n.t("Notifications.error"))
                setTransparentLoading(false);
            }
        }catch (error){
            setTransparentLoading(false);
        }
        if(transparentLoading)
            setTransparentLoading(false)
    }

    const updateFunc = async () => {
        if( name === "" || !categoriesMap.has(Number(idCategory))){
            addNotification('error', i18n.t("Notifications.errorName"))
            return
        }
        setTransparentLoading(true);
        const category: Category | undefined = categoriesMap.get(Number(idCategory));

        if(category && category.id){
            const formData = new FormData();
            formData.append('id', category.id.toString())
            formData.append('name', name);
            formData.append('description', description)
            formData.append('img', img)
            selectedProducts.forEach((product: number) => {
                formData.append('products[]', product.toString());
            });

            if (file !== null && file !== undefined) {
                formData.append('file', file);
            }
            formData.append('available', available.toString())
            try {
                const tmp: any = await updateCategory(formData);

                if (tmp) {
                    categoriesOperation({operation: "UPDATE"}, {
                        id: category.id,
                        name: name,
                        available: category.available,
                        img: tmp.img || null,
                        description: category.description
                    })
                    addNotification('success', i18n.t("Notifications.confirmed"));
                    setTransparentLoading(false);
                    handleClose()
                }
            }catch (error){
                setTransparentLoading(false);
            }
        }else{
            //notifica di errore
            addNotification('error', i18n.t("Notifications.error"))
        }

        if(transparentLoading)
            setTransparentLoading(false)
    }

    useEffect(() => {
        reload()
    }, [dataLoading]);

    useEffect(() => {
        if(isNew || categorySelected)
            setSelectedProducts([...manageProducts.existing, ...manageProducts.added].filter((element) => !manageProducts.removed.includes(element)))
    }, [manageProducts]);

    const deleteFunc = async () => {
        if(!transparentLoading && isUpdate && category?.id){
            setTransparentLoading(true);
            const tmp = await removeCategory(category.id);
            if(tmp){
                categoriesOperation({operation: "REMOVE"}, undefined, category.id)
                addNotification('success', i18n.t("Notifications.confirmed"));
                setTransparentLoading(false)
            }else{
                //notifica di errore
                addNotification('error', i18n.t("Notifications.error"));
            }
            if(transparentLoading)
                setTransparentLoading(false)
        }
    }

    const navigateToListOfElements = () => {
        const productTmp: CategoryFormData = {
            name: name,
            description: description,
            img: img,
            available: available,
            file: file,
            preview: preview,
        }
        loadTmpCategoryFormData(productTmp)
        dashboardNavigateTo(19, category?.id ? category.id + "/products" : "0/products" )
    }


    return (
        <>{dataLoading ? <Loader allPage={true} /> :
            <StyledPage>
                {transparentLoading && <Loader allPage={true} transparent={true}/>}
                <ImageChoice text={"Carica un'immagine per la tua categoria"} setPreview={updateImage}
                             image={preview && preview !== '' ? preview : requireImg(img, localname || "")}/>

                <div className={"line"}></div>

                <div className={"box"}>
                    <div className={"title"}>
                        Nome Categoria
                    </div>
                    <input
                        placeholder="Inserisci il nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div className={"line"}></div>

                <div className="boxSwitch">
                    <div className="titleSwitch">Disponibile</div>
                    <div className={"switch"}>
                        <Switch
                            checked={available}
                            onChange={(e) => setAvailable(e.target.checked)}
                        />
                    </div>
                </div>

                <div className={"line"}></div>

                <div className={"box"}>
                    <div className={"title"}>
                        Descrizione
                    </div>
                    <input
                        placeholder="Inserisci la descrizione"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>

                <div className={"line"}></div>

                <div className={"box"} onClick={() => navigateToListOfElements()}>
                    <div className={"title"}>
                        Piatti
                        <span className={"iconBoxList"}>
                            <FontAwesomeIcon icon={faAngleRight}/>
                        </span>
                    </div>
                    {selectedProducts && selectedProducts.length > 0 ?
                        <div className={"boxList"}>
                            {[...selectedProducts].map((prod) =>
                                productsMap && productsMap.has(prod) &&
                                <div className="item-row">{productsMap?.get(prod)?.name}</div>
                            )}
                        </div>
                        :
                        <div>Aggiungi Piatto</div>
                    }
                </div>

                {isUpdate && <>
                    <div className={"line"}></div>

                    <div className={"boxDelete"}>
                        <DeleteObj
                            remove={deleteFunc}
                            text={"Elimina categoria"}
                            messageTitle={i18n.t('Dashboard.categories.deleteTitle')}
                            messageBody={i18n.t('Dashboard.categories.deleteBody')}/>
                    </div>
                </>}

                <div className={"line"}></div>

                <div className={"lastLine"}></div>

                <div
                    className={(name === '') ? "saveButtonNotActive" : "saveButtonActive"}
                    onClick={() => name !== '' && (isUpdate ? updateFunc() : save())}
                >
                    Salva
                </div>
            </StyledPage>
        }
        </>
    );
};

export default CategoryPage;