import React, {createContext, useState, ReactNode, useEffect, useContext, useMemo} from 'react';
import { useLoginContext } from './LoginContext';
import {DataContextType} from "../types/context";
import {Image, Styles} from "../types/api";
import {Category, Ingredient, OperationType, Product} from "../types/types";
import {getCacheData} from "../utils/api";
import {generateSessionId, getSessionId} from "../utils/utils";
import {useParams} from "react-router-dom";

const DataContext = createContext<DataContextType | undefined>(undefined);

export const useDataContext = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
};

export const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { localname } = useParams()
    const {loading: loginLoading} = useLoginContext()

    const [styleJson, setStyleJson] = useState<Styles | null>(null);
    const [loading, setLoading] = useState<boolean>(true)

    //log per aggiornamento
    const [productsLog, setProductsLog] = useState<string>("0000:0000");
    const [categoriesLog, setCategoriesLog] = useState<string>("0000:0000");
    const [imagesLog, setImagesLog] = useState<string>("0000:0000");
    const [ingredientsLog, setIngredientsLog] = useState<string>("0000:0000");
    const [styleLog, setStyleLog] = useState<string>("0000:0000");

    const [productsByCategory, setProductsByCategory] = useState<any>(new Map());
    const [categoriesMap, setCategoriesMap] = useState<Map<number, Category>>(new Map());
    const [imageList, setImageList] = useState<Image[]>([]);
    const [ingredientsMap, setIngredientsMap] = useState<Map<number, Ingredient>>(new Map());
    const [productsMap, setProductsMap] = useState<Map<number, Product>>(new Map());

    const [count, setCount] = useState<number>(0);
    const [start, setStart] = useState<boolean>(true)

    const loadData = (data: any) => {

        if(data.categoriesCache) {
            const categories: Map<number, Category> = new Map<number, Category>(
                Object.entries(data.categoriesCache).map(([key, value]) => [parseInt(key), value as Category])
            );
            setCategoriesMap(categories);
        }

        if(data.ingredientsCache){
            const ingredients: Map<number, Ingredient> = new Map<number, Ingredient>(
                Object.entries(data.ingredientsCache).map(([key, value]) => [parseInt(key, 10), value as Ingredient])
            );
            setIngredientsMap(ingredients);
        }

        if(data.imagesCache){
            const images = [...data.imagesCache];
            setImageList(images);
        }

        if(data.productsCache){
            const products: Map<number, Product> = new Map<number, Product>(
                Object.entries(data.productsCache).map(([key, value]) => [parseInt(key, 10), value as Product])
            );
            setProductsMap(products);

            reloadProductByCategory(products);

        }

        if(data.styleCache){
            const style = data.styleCache;
            setStyleJson(style)
        }

        if(loading)
            setLoading(false)
    }

    const reloadProductByCategory = (products: Map<number, Product>) => {
        const categoryProductMap = new Map();

        products.forEach((product, productId) => {
            const categoryId = product.idCategory;
            if (!categoryProductMap.has(categoryId)) {
                categoryProductMap.set(categoryId, []);
            }
            categoryProductMap.get(categoryId).push(productId);
        })
        setProductsByCategory(categoryProductMap);
    }

    useEffect(() => {
        pollFileChanges();
    }, []);

    useEffect(() => {
        pollFileChanges();
    }, [loginLoading]);

    const goTimeout = async() => {
        const milliseconds = parseInt(process.env.REACT_APP_MILLISECONDS_LISTS || '30000', 10);
        const timeoutId = setTimeout(() => {
            pollFileChanges()
        }, milliseconds);
    }

    useEffect(() => {
        goTimeout()
    }, [count])

    const pollFileChanges = async () => {
        if (!loginLoading && !window.location.href.startsWith(process.env.REACT_APP_MY_URL + "/login")) {
            const data = start ? await getCacheData(localname || null, ["CATEGORIES", "STYLES", "INGREDIENTS", "PRODUCTS", "LOGS", "IMAGES"]) : await getCacheData(localname || null, ['LOGS'])

            const listToReload = []
            let sessionId = getSessionId();
            if(!sessionId)
                sessionId = generateSessionId();

            if(data) {
                if(!start) {
                    const allLogs = (data as any).allLogs;

                    let tmp = allLogs.categoriesLog.split(":");
                    if (tmp[0] !== sessionId && allLogs.categoriesLog !== categoriesLog) {
                        setCategoriesLog(allLogs.categoriesLog)
                        listToReload.push("CATEGORIES")
                    }

                    tmp = allLogs.productsLog.split(":");
                    if (tmp[0] !== sessionId && allLogs.productsLog !== productsLog) {
                        setProductsLog(allLogs.productsLog)
                        listToReload.push("PRODUCTS")
                    }

                    tmp = allLogs.ingredientsLog.split(":");
                    if (tmp[0] !== sessionId && allLogs.ingredientsLog !== ingredientsLog) {
                        setIngredientsLog(allLogs.ingredientsLog)
                        listToReload.push("INGREDIENTS")
                    }

                    tmp = allLogs.styleLog.split(":");
                    if (tmp[0] !== sessionId && allLogs.styleLog !== styleLog) {
                        setStyleLog(allLogs.styleLog)
                        listToReload.push("STYLES")
                    }

                    tmp = allLogs.imagesLog.split(":");
                    if (tmp[0] !== sessionId && allLogs.imagesLog !== imagesLog) {
                        setImagesLog(allLogs.imagesLog)
                        listToReload.push("IMAGES")
                    }

                    const info = await getCacheData(localname || null, listToReload)
                    if(info)
                        loadData(info)

                } else {
                    loadData(data);
                    listToReload.push("CATEGORIES")
                    listToReload.push("INGREDIENTS")
                    listToReload.push("PRODUCTS")
                    listToReload.push("STYLES")
                    listToReload.push("IMAGES")

                    setStart(false)
                }
            }
        }
        const updatedCount = count === 5000 ? 0 : count + 1;
        setCount(updatedCount);
    }

    const removeImage = (id: number, img: string) => {
        let tmp = imageList.filter((image) => image.id !== id);
        setImageList([...tmp])

        const tmpCategories: Map<number, Category> = new Map<number, Category>();

        for (const [key, obj] of categoriesMap.entries()) {
            const updatedCategory = {
                ...obj,
                img: obj.img === img ? "" : obj.img
            };

            tmpCategories.set(key, updatedCategory);
        }

        setCategoriesMap(tmpCategories);

        const tmpProducts: Map<number, Product> = new Map<number, Product>();

        for (const [key, obj] of productsMap.entries()) {
            // Crea una nuova copia dell'oggetto con l'immagine aggiornata
            const updatedProduct = {
                ...obj,
                img: obj.img === img ? "" : obj.img
            };

            tmpProducts.set(key, updatedProduct);
        }

        setProductsMap(tmpProducts);

    }

    //const waiterOperation = (op: OperationType, waiter?: Waiter?, id: number) => {
    //    switch(op){
    //        case "ADD":
    //            const tmp = [...waiters]
    //            tmp.push({id: data.id, name: data.name, surname: data.surname, email: data.email, confirmed: data.confirmed})
    //            setWaiters([...tmp])
    //            break;
    //        case "REMOVE":
    //            tmp = waiters.filter((waiter) => waiter.id !== data);
    //            setWaiters([...tmp])
    //            break;
    //        case "UPDATE":
    //            tmp = waiters.map((value, index) => {
    //                if(value.id === data.id){
    //                    return {...value, name: data.name, surname: data.surname, email: data.email}
    //                }
    //                return value;
    //            })
    //            setWaiters([...tmp])
    //            break;
    //    }
    //}

    const categoriesOperation = (op: OperationType, category?: Category, id?: number) => {
        switch(op.operation){
            case "ADD":
                if(category) {
                    const tmp: Map<number, Category> = new Map(categoriesMap)
                    tmp.set(category.id, {
                        id: category.id,
                        name: category.name,
                        img: category.img,
                        available: category.available,
                        description: category.description
                    })
                    setCategoriesMap(tmp)

                    const productsTmp = new Map(productsMap);

                    if (category.products) {
                        for (const idProd of category.products) {
                            if (idProd && productsTmp.has(idProd)) {
                                const productElement = productsTmp.get(idProd);

                                if (productElement) {
                                    productElement.idCategory = id ?? 1;
                                }
                            }
                        }
                    }
                    setProductsMap(productsTmp)
                    reloadProductByCategory(productsTmp)

                }
                break;
            case "REMOVE":
                if(id) {
                    const tmp: Map<number, Category> = new Map(categoriesMap)
                    tmp.delete(id)
                    setCategoriesMap(tmp);

                    const tmpProducts: Map<number, Product> = new Map();
                    for (const [key, obj] of productsMap.entries()) {
                        if (obj.idCategory === id) {
                            obj.idCategory = 1;
                        }
                        tmpProducts.set(key, obj);
                    }
                    setProductsMap(tmpProducts)
                }
                break;
            case "UPDATE":
                if (category && categoriesMap.has(category.id)) {
                    const tmp: Map<number, Category> = new Map(categoriesMap)
                    const updatedItem: Category = { ...tmp.get(category.id), id: category.id, name: category.name, img: category.img, available: category.available, description: category.description };
                    tmp.set(category.id, updatedItem);
                    setCategoriesMap(tmp)

                    const productsTmp = productsMap;

                    if (category.products) {

                        for( const prod of Array.from(productsMap.values())){
                            if(prod.idCategory === category.id && prod.id && !category.products.includes(prod.id)){
                                prod.idCategory = 1;
                            }else if(prod.idCategory === 1 && prod.id && category.products.includes(prod.id)){
                                prod.idCategory = category.id;
                            }
                            if(prod.id)
                                productsTmp.set(prod.id, prod);
                        }

                    }
                    setProductsMap(productsTmp)
                    reloadProductByCategory(productsTmp)
                }
                break;
        }
    }

    const ingredientsOperation = (op: OperationType, ingredient?: Ingredient, id?: number) => {
        switch(op.operation){
            case "ADD":
                if(ingredient) {
                    const tmp: Map<number, Ingredient> = new Map(ingredientsMap)
                    tmp.set(ingredient.id, {
                        id: ingredient.id,
                        name: ingredient.name,
                        price: ingredient.price,
                        available: ingredient.available,
                        addable: ingredient.addable,
                        frozen: ingredient.frozen
                    })
                    setIngredientsMap(tmp)
                }
                break;
            case "REMOVE":
                if(id) {
                    const tmp: Map<number, Ingredient> = new Map(ingredientsMap)
                    tmp.delete(id)
                    setIngredientsMap(tmp)
                    const tmpProducts: Map<number, Product> = new Map();

                    for (const [key, obj] of productsMap.entries()) {

                        if ((obj.ingredients || []).includes(id)) {
                            const updatedIngredients = obj.ingredients.filter(ingredient => ingredient !== id);
                            const updatedProduct = {...obj, ingredients: updatedIngredients};
                            tmpProducts.set(key, updatedProduct);
                        } else {
                            tmpProducts.set(key, obj);
                        }

                    }
                    setProductsMap(tmpProducts);
                }
                break;
            case "UPDATE":
                if(ingredient && ingredientsMap.has(ingredient.id)){
                    const tmp: Map<number, Ingredient> = new Map(ingredientsMap)
                    const updatedItem: Ingredient = {...tmp.get(ingredient.id), id: ingredient.id, name: ingredient.name, price: ingredient.price, frozen: ingredient.frozen, available: ingredient.available, addable: ingredient.addable}
                    tmp.set(ingredient.id, updatedItem)
                    setIngredientsMap(tmp)
                }
                break;
        }
    }

    const productsOperation = (op: OperationType, product?: Product, id?: number) => {
        switch(op.operation) {
            case "ADD":
                if(product && product?.id) {
                    const tmp: Map<number, Product> = new Map(productsMap)
                    tmp.set(product.id, {
                        id: product.id,
                        img: product.img,
                        name: product.name,
                        available: product.available,
                        price: product.price,
                        idCategory: product.idCategory,
                        ingredients: product.ingredients,
                        allergens: product.allergens,
                        tags: product.tags,
                        description: product.description,
                        options: product.options,
                    })
                    setProductsMap(tmp)
                    reloadProductByCategory(tmp)
                }
                break;
            case "REMOVE":
                if (id && productsMap.has(id)) {
                    const tmp: Map<number, Product> = new Map(productsMap)
                    tmp.delete(id)
                    setProductsMap(tmp)
                    reloadProductByCategory(tmp)
                }
                break;
            case "UPDATE":
                if (product && product.id && productsMap.has(product.id)) {
                    const tmp: Map<number, Product> = new Map<number, Product>(productsMap)
                    const updatedItem = {
                        ...tmp.get(product.id),
                        allergens: product.allergens,
                        available: product.available,
                        id: product.id,
                        img: product.img,
                        idCategory: product.idCategory,
                        ingredients: product.ingredients,
                        name: product.name,
                        price: product.price,
                        tags: product.tags,
                        description: product.description,
                        options: product.options
                    }
                    tmp.set(product.id, updatedItem)
                    setProductsMap(tmp)
                    reloadProductByCategory(tmp)
                }
                break;
        }
    }

    const categoryInProducts = (idCategory: number) => {
        for (const product of productsMap.values()) {
            if (product.idCategory === idCategory) {
                return true;
            }
        }
        return false;
    }

    const ingredientInProducts = (idIngredient: number) => {
        for(const product of productsMap.values()){
            if(product.ingredients.includes(idIngredient))
                return true;
        }
        return false;
    }


    const dashboardMemo = useMemo<DataContextType>(() => ({
        removeImage,
        productsOperation,
        ingredientsOperation,
        categoriesOperation,
        categoryInProducts,
        ingredientInProducts,
        styleJson,
        ingredientsMap,
        productsMap,
        categoriesMap,
        imageList,
        productsLog,
        categoriesLog,
        loading,
        productsByCategory,
    }), [
        loading, imageList, productsByCategory,
        ingredientsMap, categoriesMap,
        productsMap, styleJson
    ]);

    return (
        <DataContext.Provider value={dashboardMemo}>
            {children}
        </DataContext.Provider>
    );
};