import styled from "styled-components";

export const DeleteGeneralContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;

    .delete{
        background: white;
        color: #FF613C;
        width: 92%;
        padding: 10px 4%;
        font-size: 1.2em;
        border-radius: 10px;
        display: flex;
        justify-content: initial;
        align-items: center;
    }
    
`



export const StyledNavbar = styled.div`
    min-height: 107px;
    height: 16.91vh;

    .containerNavbar{
        position: fixed;
        width: 100%;
        max-width: 500px;
        z-index: 1000;
    }
    
    @media only screen and (min-width: 550px) and (max-width: 936px)  {

    }

    //@media only screen and (max-width: 551px)  {

        .customIcon{
            font-size: 4vh
        }
        
        #dropdown-basic:focus {
            box-shadow: none;
        }

        .custom-dropdown {
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
        }

        .custom-dropdown::after {
            display: none;
        }
        
        .title {
            width: 100%;
            min-height: 70px;
            height: 11.11vh;
            background-color: #2B2F37;
            display: flex;
            align-items: flex-end;
            color: white;
            font-size: 2rem;
            font-weight: bold;
            justify-content: space-between;
        }

        .icons{
            width: 100%;
            padding: 0 4%;
            height: 5.8vh;
            min-height: 37px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: ${({ theme }) => theme.colors.background}
        }

        .icon{
            height: 5vh;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4vh;
        }
    //}
`
export const StyledNotification = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    padding: 16px;
    z-index: 9999;

    .notification {
        margin-bottom: 8px;
        padding: 16px;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.success {
            background-color: #4caf50; /* Green */
        }

        &.info {
            background-color: #2196f3; /* Blue */
        }

        &.error {
            background-color: #f44336; /* Red */
        }
    }

    `

export const StyledSearch = styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors.text};
    align-items: center;
    width: 92%;
    max-width: 500px;
    margin: 1rem;
    background: ${({ theme }) => theme.colors.secondaryBackground};
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.text};
    }

    input {
        flex-grow: 1;
        //height: 100%;
        border: none;
        outline: none;
        padding: 0.5rem;
        font-size: 1rem;
        color: ${({ theme }) => theme.colors.text};
        background: ${({ theme }) => theme.colors.secondaryBackground};
        border-radius: 0 5px 5px 0;
    }

    //@media only screen and (max-width: 551px) {
        width: 92%;
        height: 37px;
        margin: 0.5rem 4%;

        .icon {
            padding: 0 0.5rem;
            cursor: pointer
        }

        input {
            padding: 0.5rem;
            font-size: 0.875rem;
            //height: 36px
        }
   // }
`