import React, {useEffect, useState} from 'react';
import {StyledDashboard} from "./styled";
import Search from "../components/common/Search";
import {useDataContext} from "../context/DataContext";
import Loader from "../components/common/Loader";
import {useUtilitiesContext} from "../context/UtilitiesContext";
import SummaryRow from "../components/specific/summary/SummaryRow";

const SummaryListPage: React.FC = () => {
    const { loading: dataLoading, categoriesMap, ingredientsMap, productsMap } = useDataContext()
    const { setNavbarTitle, dashboardNavigateTo } = useUtilitiesContext()
    const [search, setSearch] = useState('')

    useEffect(() => {
        setSearch('')
        setNavbarTitle({title: "Sommario", iconRight: {type: "PLUS", menu: [{body: "Aggiungi Categoria", onClick: () => dashboardNavigateTo(5)}, {body: "Aggiungi Ingrediente", onClick: () => dashboardNavigateTo(3)}, {body: "Aggiungi Prodotto", onClick: () => dashboardNavigateTo(4)}]}})
    }, []);

    const searchFunction = (value: string) => {
        setSearch(value)
    }

    return (
        <>
            {(dataLoading) ? <Loader allPage={true}/> :
                <StyledDashboard>
                    <Search search={search} onChange={searchFunction}/>
                    <SummaryRow
                        title={"Categorie"}
                        list={search.trim() === "" ? Array.from(categoriesMap.values()) : Array.from(categoriesMap.values()).filter((obj, index) => obj.name.toLowerCase().includes(search.toLowerCase()))}
                        type={"CATEGORIES"}/>
                    <SummaryRow
                        title={"Prodotti"}
                        list={search.trim() === "" ? Array.from(productsMap.values()) : Array.from(productsMap.values()).filter((obj, index) => obj.name.toLowerCase().includes(search.toLowerCase()))}
                        type={"PRODUCTS"}/>
                    <SummaryRow
                        title={"Ingredienti"}
                        list={search.trim() === "" ? Array.from(ingredientsMap.values()) : Array.from(ingredientsMap.values()).filter((obj, index) => obj.name.toLowerCase().includes(search.toLowerCase()))}
                        type={"INGREDIENTS"}/>

                </StyledDashboard>
            }
        </>
    );
};

export default SummaryListPage;