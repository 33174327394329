import React, {createContext, useState, ReactNode, useContext, useEffect, useMemo} from 'react';
import { ProductContextType } from "../types/context";
import {Product, ProductFormData} from "../types/types";
import {useNotification} from "./NotificationContext";

export interface ManageElements {
    existing: number[]
    added: number[]
    removed: number[]
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

export const useProductContext = () => {
    const context = useContext(ProductContext);
    if (!context) {
        throw new Error('useProductContext must be used within a ProductProvider');
    }
    return context;
};

export const ProductProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const { addNotification } = useNotification()

    const [productSelected, setProductSelected] = useState<Product | null>(null)

    const [manageIngredients, setManageIngredients] = useState<ManageElements>({existing: [], added: [], removed: []})
    const [manageAllergens, setManageAllergens] = useState<ManageElements>({existing: [], added: [], removed: []})
    const [manageTags, setManageTags] = useState<ManageElements>({existing: [], added: [], removed: []})
    const [tmpId, setTmpId] = useState<number | null>(null)

    const [isNew, setIsNew] = useState<boolean>(false)

    const [productTmpFormData, setProductTmpFormData] = useState<ProductFormData | null>(null)

    const loadTmpProduct = (product: Product) => {
        setProductSelected({...product});
        setManageIngredients({existing: product.ingredients || [], added: [], removed: []})
        setManageAllergens({existing: product.allergens || [], added: [], removed: []})
        setManageTags({existing: product.tags || [], added: [], removed: []})
        setIsNew(false)
    }

    const resetTmpProduct = () => {
        if(productSelected){
            setProductSelected(null);
            setManageIngredients({existing: [], added: [], removed: []})
            setManageAllergens({existing: [], added: [], removed: []})
            setManageTags({existing: [], added: [], removed: []})
            setIsNew(false)
        }
    }

    const setTmpIsNew = () => {
        setIsNew(true)
    }

    const addTmpId = (id: number | null) => {
        setTmpId(id)
    }

    const addIngredient = (idIngredient: number) => {
        setManageIngredients(prevManageIngredients => {
            const updatedRemoved = prevManageIngredients.removed.filter(value => value !== idIngredient);
            const updatedAdded = new Set(prevManageIngredients.added);

            if (!prevManageIngredients.existing.includes(idIngredient)) {
                updatedAdded.add(idIngredient);
            }

            return {
                ...prevManageIngredients,
                removed: updatedRemoved,
                added: Array.from(updatedAdded)
            };
        });

        addNotification('success', 'Ingrediente aggiunto')
    };

    const addAllergen = (idAllergen: number) => {
        setManageAllergens(prevManageAllergens => {
            const updatedRemoved = prevManageAllergens.removed.filter(value => value !== idAllergen);
            const updatedAdded = new Set(prevManageAllergens.added);

            if (!prevManageAllergens.existing.includes(idAllergen)) {
                updatedAdded.add(idAllergen);
            }

            return {
                ...prevManageAllergens,
                removed: updatedRemoved,
                added: Array.from(updatedAdded)
            };
        });

        addNotification('success', 'Allergene aggiunto')
    };

    const addTag = (idTag: number) => {
        setManageTags(prevManageTags => {
            const updatedRemoved = prevManageTags.removed.filter(value => value !== idTag);
            const updatedAdded = new Set(prevManageTags.added);

            if (!prevManageTags.existing.includes(idTag)) {
                updatedAdded.add(idTag);
            }

            return {
                ...prevManageTags,
                removed: updatedRemoved,
                added: Array.from(updatedAdded)
            };
        });

        addNotification('success', 'Tag aggiunto')
    };

    const removeIngredient = (idIngredient: number) => {
        setManageIngredients(prevManageIngredients => {
            const updatedAdded = prevManageIngredients.added.filter(value => value !== idIngredient);
            const updatedRemoved = new Set(prevManageIngredients.removed);
            updatedRemoved.add(idIngredient);

            return {
                ...prevManageIngredients,
                added: updatedAdded,
                removed: Array.from(updatedRemoved)
            };
        });

        addNotification('success', 'Ingrediente rimosso')
    };

    const removeAllergen = (idAllergen: number) => {
        setManageAllergens(prevManageAllergens => {
            const updatedAdded = prevManageAllergens.added.filter(value => value !== idAllergen);
            const updatedRemoved = new Set(prevManageAllergens.removed);
            updatedRemoved.add(idAllergen);

            return {
                ...prevManageAllergens,
                added: updatedAdded,
                removed: Array.from(updatedRemoved)
            };
        });

        addNotification('success', 'Allergene rimosso')
    };

    const removeTag = (idTag: number) => {
        setManageTags(prevManageTags => {
            const updatedAdded = prevManageTags.added.filter(value => value !== idTag);
            const updatedRemoved = new Set(prevManageTags.removed);
            updatedRemoved.add(idTag);

            return {
                ...prevManageTags,
                added: updatedAdded,
                removed: Array.from(updatedRemoved)
            };
        });

        addNotification('success', 'Tag rimosso')
    };

    const loadTmpProductFormData = (product: ProductFormData) => {
        setProductTmpFormData(product)
    }

    const dashboardMeno = useMemo<ProductContextType>(() => ({
        loadTmpProduct,
        resetTmpProduct,
        addIngredient,
        addAllergen,
        addTag,
        removeIngredient,
        removeAllergen,
        removeTag,
        setTmpIsNew,
        loadTmpProductFormData,
        addTmpId,
        productTmpFormData,
        isNew,
        productSelected,
        manageIngredients,
        manageAllergens,
        manageTags,
    }), [productSelected,
        manageIngredients,
        manageAllergens,
        manageTags,
        isNew,
        productTmpFormData
    ])

    return (
        <ProductContext.Provider value={dashboardMeno}>
            {children}
        </ProductContext.Provider>
    );
};