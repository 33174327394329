import styled from "styled-components";
import React from "react";

export const StyledDashboard = styled.div`
    
`

export const StyledPage = styled.div`
    display: flex;
    flex-direction: column;

    .iconRight{
        position: absolute;
        right: 4%;
    }
    //@media only screen and (max-width: 550px)  {
        width: 100%;

        .line{
            width: 100%;
            height: 10px;
            background: ${({ theme }) => theme.colors.primary};
            opacity: 0.4
        }

        .box{
            background-color: #fff;
            padding: 15px 4%;
            border-radius: 10px;
        }
    
    .boxSwitch{
        background-color: #fff;
        padding: 15px 4%;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .titleSwitch{
        font-size: 1.2em;
        font-weight: bold;
        color: #333;
    }

        .title{
            font-size: 1.2em;
            font-weight: bold;
            margin-bottom: 10px;
            color: #333;
        }

        .box input{
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            box-sizing: border-box;
            background-color: ${({ theme }) => theme.colors.background};
        }

        .iconBoxList{
            position: absolute;
            right: 4%;
        }

        .boxList{
            margin-bottom: 5px;
            background-color: ${({ theme }) => theme.colors.background};
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
        }
        

        .lastLine{
            background: ${({ theme }) => theme.colors.background};
            height: 5vh;
            max-height: 70px;
            min-height: 50px;
        }

        .saveButtonNotActive{
            width: 100%;
            max-width: 500px;
            text-align: center;
            background-color: ${({ theme }) => theme.colors.silver};
            color: ${({ theme }) => theme.colors.background};
            position: fixed;
            bottom: 0;
            height: 4vh;
            max-height: 60px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .saveButtonActive{
            width: 100%;
            max-width: 500px;
            text-align: center;
            background-color: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.background};
            position: fixed;
            bottom: 0;
            height: 4vh;
            max-height: 60px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .delete{
            background: white;
            color: #FF613C;
            width: 92%;
            padding: 10px 4%;
            font-size: 1.2em;
            border-radius: 10px;
            display: flex;
            justify-content: initial;
            align-items: center;
        }

    //}
`

export const StyledProduct = styled.div`

    display: flex;
    flex-direction: column;
    
    //@media only screen and (max-width: 550px)  {
        width: 100%;
        
        .imgDiv{
            width: 8rem;
            height: 8rem;
            max-width: 250px;
            max-height: 250px;
        }
        
        .boxImage{
            width: 92%;
            display: flex;
            justify-content: center;
            align-content: center;
            background: white;
            margin: 10px 4%;
        }
        
        .line{
            width: 100%;
            height: 10px;
            background: ${({ theme }) => theme.colors.primary};
            opacity: 0.4
        }
        
        .chargeText{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

    .boxSwitch{
        background-color: #fff;
        padding: 15px 4%;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .titleSwitch{
        font-size: 1.2em;
        font-weight: bold;
        color: #333;
    }
    
        .box{
            background-color: #fff;
            padding: 15px 4%;
            border-radius: 10px;
        }
        
        .title{
            font-size: 1.2em;
            font-weight: bold;
            margin-bottom: 10px;
            color: #333;
        }
        
        .box input{
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            box-sizing: border-box;
            background-color: ${({ theme }) => theme.colors.background};
        }
        
        .iconBoxList{
            position: absolute;
            right: 4%;
        }
        
        .boxList{
            margin-bottom: 5px;
            background-color: ${({ theme }) => theme.colors.background};
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
        }
        
        .delete{
            background: white;
            color: #FF613C;
            width: 92%;
            padding: 10px 4%;
            font-size: 1.2em;
            border-radius: 10px;
            display: flex;
            justify-content: initial;
            align-items: center;
        }
        
        .lastLine{
            background: ${({ theme }) => theme.colors.background};
            height: 5vh;
            max-height: 70px;
            min-height: 50px;
        }

        .saveButtonNotActive{
            width: 100%;
            max-width: 500px;
            text-align: center;
            color: ${({ theme }) => theme.colors.background};
            background-color: ${({ theme }) => theme.colors.silver};
            position: fixed;
            bottom: 0;
            height: 4vh;
            max-height: 60px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .saveButtonActive{
            width: 100%;
            max-width: 500px;
            text-align: center;
            background-color: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.background};
            position: fixed;
            bottom: 0;
            height: 4vh;
            max-height: 60px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .option-inputs {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            

            label {
                margin-left: 10px;
                display: flex;
                align-items: center;
            }

            button {
                margin: 10px 10px;
                padding: 10px;
                background-color: #FF613C;
                color: #fff;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                &:disabled {
                    background-color: #ccc;
                }
            }
        }

        .add-option-button {
            padding: 10px;
            background-color: #FF613C;
            color: #fff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            margin-top: 10px;
        }
        
        .existing-options {
            .option {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                background-color: #f9f9f9;
                border: 1px solid #ccc;
                border-radius: 5px;
                margin-bottom: 5px;

                button {
                    background-color: #FF613C;
                    color: #fff;
                    border: none;
                    border-radius: 5px;
                    padding: 5px 10px;
                    cursor: pointer;
                }
            }
        //}
        
    }
`

export const StyledIngredient = styled.div`
    display: flex;
    
`


export const StyledTemplate = styled.div`
    display: flex;
    flex-direction: column;

    .containerTemplate{
        height: 65vh;
        width: 100%;
        background: ${({ theme }) => theme.colors.secondary};
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .optionsTemplate{
        height: 18vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    
    .containerInfoTemplate{
        width: 70%;
        height: 80%;
        background: #D9D9D9;
    }
    
    .boxBottomContainerTemplate{
        
    }

    .titleBottomTemplate{
        text-align: center
    }
    
    .boxBottomTemplate{
        width: 10vh;
        height: 10vh;
        max-height: 150px;
        max-width: 150px;
        min-height: 80px;
        min-width: 80px;
        background: ${({ theme }) => theme.colors.secondary};
    }
`

export const StyledQRCode = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 79.09vh;
    max-height: calc(84.89vh - 97px);

    //@media only screen and (max-width: 550px) {
        .container {
            width: 80%;
            max-width: 300px;
        }
        
        .downloadButton {
            width: 100%;
            max-width: 500px;
            text-align: center;
            background-color: ${({theme}) => theme.colors.primary};
            color: ${({theme}) => theme.colors.background};
            position: fixed;
            bottom: 0;
            height: 4vh;
            max-height: 60px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    //}
`

export const StyledPageList = styled.div`
    display: flex;
    flex-direction: column;

    //@media only screen and (max-width: 550px) {
        .box {
            display: flex;
            flex-direction: row;
            padding: 10px 10px 10px 4%;
            box-sizing: border-box;
            background-color: white;
            border-bottom: 1px solid #ccc;
        }

        .box img {
            border-radius: 5px;
        }

        .boxDiv {
            width: 80px;
            height: 80px;
        }

        .name {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 80px - 0.5rem);
            margin-left: 0.5rem;
            font-weight: bold;
        }
        
        .iconRight{
            position: absolute;
            right: 4%;
        }

        .boxDragging {
            background: lightgrey;
        }
    }
    
`


export const StyledCategory = styled.div`
    display: flex;
    flex-direction: column;

    
`

export const StyledElementsPage = styled.div`
    display: flex;
    flex-direction: column;
    
    //@media only screen and (max-width: 550px) {
        .row1{
            display: flex;
            width: 100%;
            padding: 0 0.4rem;
            border-bottom: 1px solid silver;
            justify-content: space-between;
        }
        
        .name, .icon{
            width: auto
        }
        
        .icon{
            text-align: right;
            color: ${({theme}) => theme.colors.primary}
        }

    .saveButtonActive{
        width: 100%;
        max-width: 500px;
        text-align: center;
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.background};
        position: fixed;
        bottom: 0;
        height: 4vh;
        max-height: 60px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    //}
    
`

export const StyledSummaryPage = styled.div`
    display: flex;
    flex-direction: column;
    
    //@media only screen and (max-width: 550px) {
        .row{
            display: flex;
            width: 100%;
            padding: 0 0.4rem;
            border-bottom: 1px solid silver;
            justify-content: space-between;
        }
        
        .name, .icon{
            width: auto
        }
        
        .icon{
            text-align: right;
            color: ${({theme}) => theme.colors.primary}
        }
    //}
    
`
