import React, { useEffect, useState } from 'react';
import { StyledElementsPage } from './styled';
import { useDataContext } from '../context/DataContext';
import { useUtilitiesContext } from '../context/UtilitiesContext';
import Loader from '../components/common/Loader';
import Search from '../components/common/Search';
import { Allergen, Ingredient, Tag } from '../types/types';
import { allergensMap, tagsMap } from '../utils/utils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus, faCircleMinus} from "@fortawesome/free-solid-svg-icons";
import {ManageElements, useProductContext} from "../context/ProductContext";
import {scryRenderedComponentsWithType} from "react-dom/test-utils";

interface ElementsPageProps {
    type: 'ALLERGENS' | 'INGREDIENTS' | 'TAGS';
    listType: "IN_PRODUCT" | "ADD_EXISTING";
}

interface IconType {
    icon: 'PLUS' | 'MINUS' | 'NO_ACTIVE';
}

interface RowProps {
    name: string;
    //icon: IconType;
    click: () => void;
}

const Row: React.FC<RowProps> = ({ name, /*icon,*/ click }) => {
    return (
        <div className='row1' onClick={click}>
            <div className='name'>{name}</div>
            <div className='icon'>
                {/*icon.icon === "PLUS" && <FontAwesomeIcon icon={faCirclePlus} />}
                {icon.icon === "MINUS" && <FontAwesomeIcon icon={faCircleMinus} />*/}
            </div>
        </div>
    );
};

const ElementsPage: React.FC<ElementsPageProps> = ({
                                                       type,
                                                       listType
                                                   }) => {
    const { loading: dataLoading, ingredientsMap, productsMap } = useDataContext();
    const { localname, goBack, transparentLoading, dashboardNavigateTo, setNavbarTitle } = useUtilitiesContext();
    const { isNew, manageTags, manageAllergens, manageIngredients, addIngredient, addAllergen, addTag, removeIngredient, removeAllergen, removeTag, productSelected } = useProductContext()

    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        reload()
    }, []);

    useEffect(() => {
        reload()
    }, [listType, type]);

    const reload = () => {
        setSearch('')

        if(!productSelected && !isNew)
            window.location.href = "/" + localname //TODO: aggiungere url al prodotto che si stava modificando
        let title = "Ingredienti";
        if(type === "ALLERGENS"){
            title = "Allergeni"
        }else if(type === "TAGS"){
            title = "Tag"
        }
        if(listType === "ADD_EXISTING")
            title = "Aggiungi ".concat(title)
        if(type === "INGREDIENTS")
            setNavbarTitle({back: {back: true, custom: false},title: title, iconRight: {type: listType === "IN_PRODUCT" ? "PLUS" : "NONE", menu: [{body: "Aggiungi esistente", onClick: () => dashboardNavigateTo(1, productSelected?.id + "/ingredients/addExisting")}, {body: "Aggiungi nuovo", onClick: () => dashboardNavigateTo(1, productSelected?.id + "/ingredients/addNew")}]}})
        else
            setNavbarTitle({back: {back: true, custom: false}, title: title, iconRight: {type: listType === "IN_PRODUCT" ? "PLUS" : "NONE", menu: [{body: "Aggiungi esistente", onClick: () => type === "ALLERGENS" ? dashboardNavigateTo(1, productSelected?.id + "/allergens/addExisting") : dashboardNavigateTo(1, productSelected?.id + "/tags/addExisting")}]}})
    }

    //const getIcon = (id: number): IconType => {
    //    if (elementsExisting.includes(id) || elementsAdded.includes(id)) {
    //        return elementsRemoved.includes(id) ? { icon: 'PLUS' } : { icon: 'MINUS' };
    //    }
    //    return { icon: 'PLUS' };
    //};

    const sortElements = (elements: (Ingredient | Allergen | Tag)[]) => {
        return elements.sort((a, b) => {
            //const iconA = getIcon(a.id).icon;
            //const iconB = getIcon(b.id).icon;
//
            //// First order by icon, with 'PLUS' before 'MINUS'
            //if (iconA === 'MINUS' && iconB === 'PLUS') return -1;
            //if (iconA === 'PLUS' && iconB === 'MINUS') return 1;

            // in ordine alfabetico
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    };

    const remove = (id: number) => {
        switch (type){
            case "ALLERGENS":
                removeAllergen(id)
                break;
            case "INGREDIENTS":
                removeIngredient(id)
                break;
            case "TAGS":
                removeTag(id)
                break;
        }
    }

    const add = (id: number) => {
        switch (type){
            case "ALLERGENS":
                addAllergen(id)
                break;
            case "INGREDIENTS":
                addIngredient(id)
                break;
            case "TAGS":
                addTag(id)
                break;
        }
    }

    const renderElements = (elements: (Ingredient | Allergen | Tag)[]) => {
        let newManage: ManageElements;
        switch (type){
            case "ALLERGENS":
                newManage = manageAllergens;
                break;
            case "INGREDIENTS":
                newManage = manageIngredients;
                break;
            case "TAGS":
                newManage = manageTags;
                break;
        }
        return sortElements(
            elements
                .filter((element) =>
                    listType === "IN_PRODUCT" ?
                        (newManage.existing.includes(element.id) || newManage.added.includes(element.id)) && !newManage.removed.includes(element.id)
                        :
                        (!newManage.existing.includes(element.id) && !newManage.added.includes(element.id)) || newManage.removed.includes(element.id))
                .filter((element) => search === '' || element.name.toLowerCase().includes(search.toLowerCase()))
        ).map((element) => {
            //const icon = getIcon(element.id);

            return (
                <Row
                    key={element.id}
                    name={element.name}
                    //icon={icon}
                    click={() => listType === 'IN_PRODUCT' ? remove(element.id) : add(element.id)}
                />
            );
        });
    };

    const searchElements = (value: string) => {
        setSearch(value)
    }

    return (
        <>
        {(dataLoading) ? <Loader allPage={true}/> :
        <StyledElementsPage>
            {transparentLoading && <Loader allPage={true} transparent={true}/>}
            <Search search={search} onChange={searchElements} />
            {type === 'INGREDIENTS' && ingredientsMap && renderElements(Array.from(ingredientsMap.values()))}
            {type === 'ALLERGENS' && allergensMap && renderElements(Array.from(allergensMap.values()))}
            {type === 'TAGS' && tagsMap && renderElements(Array.from(tagsMap.values()))}
            <div className={'saveButtonActive'} onClick={() => goBack()}>Indietro</div>
        </StyledElementsPage>}
            </>
    );
};

export default ElementsPage;
