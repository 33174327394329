import React, {useEffect, useState} from 'react';
import {useDataContext} from "../context/DataContext";
import {useUtilitiesContext} from "../context/UtilitiesContext";
import Loader from "../components/common/Loader";
import {useLoginContext} from "../context/LoginContext";
import {StyledPage} from "./styled";
import {tagsMap} from "../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

const ProfilePage: React.FC = () => {
    const { loading: dataLoading, styleJson } = useDataContext()
    const { setNavbarTitle, transparentLoading , dashboardNavigateTo } = useUtilitiesContext()
    const { user } = useLoginContext()

    useEffect(() => {
        setNavbarTitle({title: "Profilo", iconRight: {type: "LOGOUT", menu: [{body: 'Logout', onClick: () => dashboardNavigateTo(90)}]}})
    }, []);

    return (
        <>
            {dataLoading ? <Loader allPage={true} /> :
                <StyledPage>
                    {transparentLoading && <Loader allPage={true} transparent={true}/>}
                    <div className={"box"}>
                        <div className={"title"}>
                            Email
                        </div>
                        <div className={"boxList"}>
                            <div className="item-row">{user?.email}</div>
                        </div>
                    </div>

                    <div className={"line"}></div>

                    <div className={"box"} onClick={() => dashboardNavigateTo(18)}>
                        <div className={'iconRight'}><FontAwesomeIcon icon={faAngleRight}/></div>
                        <div className={"title"}>
                            Stato Abbonamento
                        </div>
                        <div className={"boxList"}>
                            <div className="item-row">Attivo</div>
                        </div>
                    </div>

                    <div className={"line"}></div>

                    <div className={"box"} onClick={() => dashboardNavigateTo(17)}>
                        <div className={'iconRight'}><FontAwesomeIcon icon={faAngleRight}/></div>
                        <div className={"title"}>
                            <span>Info ristorante</span>
                        </div>
                        <div className={"boxList"}>
                            <div className="item-row">email@email.com</div>
                            <div className="item-row">email@email.com</div>
                            <div className="item-row">email@email.com</div>
                        </div>
                    </div>

                    <div className={"line"}></div>

                    <div className={"box"} onClick={() => dashboardNavigateTo(22)}>
                        <div className={'iconRight'}><FontAwesomeIcon icon={faAngleRight}/></div>
                        <div className={"title"}>
                            Cambia password
                        </div>
                    </div>

                    <div className={"line"}></div>

                    {/*<div className={"lastLine"}></div>

                    <div className={'saveButtonActive'}>Salva</div>*/}

                </StyledPage>
            }
        </>
    );
};

export default ProfilePage;