import React from 'react';
import './App.css';
import './i18n'
import 'bootstrap/dist/css/bootstrap.min.css';
import {LoginProvider} from "./context/LoginContext";
import {UtilitiesProvider} from "./context/UtilitiesContext";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import DashboardLayout from "./components/Layout/DashboardLayout";
import DashboardPage from "./pages/DashboardPage";
import ProductPage from "./pages/ProductPage";
import LoginPage from "./pages/LoginPage";
import {ThemeProvider} from "./context/ThemeContext";
import {DataProvider} from "./context/DataContext";
import IngredientPage from "./pages/IngredientPage";
import CategoryPage from "./pages/CategoryPage";
import QRCodePage from "./pages/QRCodePage";
import ProfilePage from "./pages/ProfilePage";
import SummaryListPage from "./pages/SummaryListPage";
import ElementsPage from "./pages/ElementsPage";
import {ProductProvider} from "./context/ProductContext";
import {NotificationProvider} from "./context/NotificationContext";
import BillingPage from "./pages/BillingPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import InfoPage from "./pages/InfoPage";
import TemplatePage from "./pages/TemplatePage";
import {CategoryProvider} from "./context/CategoryContext";
import ElementsProductPage from "./pages/ElementsProductPage";
import axios from "axios";
import RegisterPage from "./pages/RegisterPage";
import AccountConfirmationPage from "./pages/AccountConfirmationPage";

function App() {

    return (
      <BrowserRouter>
        {/*<NotificationProvider>
          <NotificationContainer />
          <Banner />*/}
          <ThemeProvider>

            <Routes>
              <Route path="/" element = {<LoginProvider><LoginPage/></LoginProvider>} />
              <Route path="/login" element = {<LoginProvider><LoginPage/></LoginProvider>} />
                <Route path="/register" element = {<LoginProvider><RegisterPage/></LoginProvider>} />
              {/*<Route path="/logout" element={<LoginProvider><LogoutPage/></LoginProvider>} />*/}
              <Route path="/:localname/*" element={<NewDashboardRoutes />} />
                <Route path="/confirmAccount/:id/:code" element={<AccountConfirmationPage/>}/>
              {/*
               <Route path="/confirmAccount/:id/:code" element={<ConfirmAccount/>}/>
            <Route path="/emailNotConfirmed" element={<LoginProvider><EmailNotConfirmed/></LoginProvider>}/>
            <Route path="/recoverPassword/:code" element={<RecoverPassword/>}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/privacy-policy" element={<Privacy/>}/>
            <Route path="*" element={<ErrorPage/>} />*/}
            </Routes>
          </ThemeProvider>

      </BrowserRouter>
  );
}

const ProvidersRoutes = () =>
    <CategoryProvider>
        <ProductProvider>
            <Routes>
                <Route path="/Dashboard" element={<DashboardPage/>} />
                <Route path="Product/:idProduct" element={<ProductPage isUpdate={true}/>} />
                <Route path="Product/:idProduct/ingredients" element={<ElementsPage type={"INGREDIENTS"} listType={"IN_PRODUCT"} />} />
                <Route path="Product/:idProduct/ingredients/addExisting" element={<ElementsPage type={"INGREDIENTS"} listType={"ADD_EXISTING"} />} />
                <Route path="Product/:idProduct/ingredients/addNew" element={<IngredientPage inProduct={true} />} />
                <Route path="Product/:idProduct/allergens" element={<ElementsPage type={"ALLERGENS"} listType={"IN_PRODUCT"} />} />
                <Route path="Product/:idProduct/allergens/addExisting" element={<ElementsPage type={"ALLERGENS"} listType={"ADD_EXISTING"} />} />
                <Route path="Product/:idProduct/tags" element={<ElementsPage type={"TAGS"} listType={"IN_PRODUCT"} />} />
                <Route path="Product/:idProduct/tags/addExisting" element={<ElementsPage type={"TAGS"} listType={"ADD_EXISTING"} />} />
                <Route path="Category/:idCategory" element={<CategoryPage isUpdate={true}/>} />
                <Route path="Category/:idCategory/products" element={<ElementsProductPage listType={"IN_PRODUCT"}/>} />
                <Route path="Category/:idCategory/products/addExisting" element={<ElementsProductPage listType={"ADD_EXISTING"}/>} />
                <Route path="Category/:idCategory/products/addNew" element={<ProductProvider><ProductPage isUpdate={false} inCategory={true}/></ProductProvider>} />

                <Route path="Ingredient/:idIngredient" element={<IngredientPage isUpdate={true}/>} />
                <Route path="AddProduct" element={<ProductPage isUpdate={false}/>} />
                <Route path="AddCategory" element={<CategoryPage isUpdate={false}/>} />
                <Route path="AddIngredient" element={<IngredientPage isUpdate={false}/>} />
            </Routes>
        </ProductProvider>
    </CategoryProvider>



const NewDashboardRoutes = () => (
    <LoginProvider>
        <DataProvider>
            <UtilitiesProvider>
                <NotificationProvider>
                    <Routes>
                      <Route path="/" element={<DashboardLayout />}>

                        <Route path="/*" element={<ProvidersRoutes/>} />
                        <Route path="QRCode" element={<QRCodePage/>} />
                        <Route path="Profile" element={<ProfilePage/>} />
                        <Route path="Summary" element={<SummaryListPage/>} />
                        <Route path="Template" element={<TemplatePage/>} />

                        <Route path="Info" element={<InfoPage/>} />
                        <Route path="ChangePassword" element={<ChangePasswordPage/>} />
                        <Route path="BillingInfo" element={<BillingPage/>} />
                      </Route>
                    </Routes>
                </NotificationProvider>
            </UtilitiesProvider>
        </DataProvider>
    </LoginProvider>
)

export default App;
