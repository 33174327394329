import * as React from 'react';
import {StyledNavbar} from "./styled";
import {useTranslation} from "react-i18next";
import {useUtilitiesContext} from "../../context/UtilitiesContext";
import {faArrowLeft, faPlusCircle, faQrcode, faUser, faChartBar, faTableList, faShareNodes, faArrowRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FastfoodIcon from '@mui/icons-material/Fastfood';
import MoreVoiceMenu from "./MoreVoiceMenu";
import {useEffect, useState} from "react";

const Navbar: React.FC = () => {
    const { i18n } = useTranslation();
    const { navbarTitle, goBack, dashboardNavigateTo, localname } = useUtilitiesContext()
    const [moreOpen, setMoreOpen] = useState<boolean>(false)

    useEffect(() => {
        setMoreOpen(false)
    }, []);

    const shareUrl = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Scopri il nostro menu digitale!',
                    text: 'Guarda il nostro menu completo e scegli i tuoi piatti preferiti. Visita il nostro sito per ulteriori dettagli!',
                    url: process.env.REACT_APP_MY_URL_CLIENT + "/" + localname,
                });
                console.log('Content shared successfully');
            } catch (error) {
                console.error('Error sharing content:', error);
            }
        } else {
            console.warn('Web Share API is not supported in your browser.');
        }
    };

    const onMoreClose = () => {
        setMoreOpen(false)
    }

    return (
        <StyledNavbar>
            <div className={"containerNavbar"}>
                <MoreVoiceMenu isOpen={moreOpen} onClose={onMoreClose} menu={navbarTitle.iconRight.menu}/>
                <div className="title">
                    <span style={{margin: '0.5rem'}}>
                        {navbarTitle.back && navbarTitle.back.back && <FontAwesomeIcon style={{marginRight: '1rem'}} onClick={() => navbarTitle.back?.custom && navbarTitle.back?.onClick ? navbarTitle.back?.onClick() : goBack()} icon={faArrowLeft}/>}
                        {navbarTitle.title}
                    </span>
                    {navbarTitle.iconRight.type === "PLUS" && <span style={{margin: '0.5rem'}}><FontAwesomeIcon icon={faPlusCircle} onClick={() => navbarTitle.iconRight.menu.length === 1 ? navbarTitle.iconRight.menu[0].onClick() : setMoreOpen(true)}/></span>}
                    {navbarTitle.iconRight.type === "LOGOUT" && <span style={{margin: '0.5rem'}}><FontAwesomeIcon icon={faArrowRightFromBracket} onClick={() => navbarTitle.iconRight.menu.length === 1 && navbarTitle.iconRight.menu[0].onClick()}/></span>}
                    {navbarTitle.iconRight.type === "SHARE" && <span style={{margin: '0.5rem'}}><FontAwesomeIcon icon={faShareNodes} onClick={() => shareUrl()}/></span>}
                </div>
                <div className="icons">
                    <div className="icon"><FontAwesomeIcon icon={faTableList} onClick={() => dashboardNavigateTo(23)}/></div>
                    <div className="icon"><FontAwesomeIcon icon={faChartBar} onClick={() => dashboardNavigateTo(21)}/></div>
                    <div className="icon"><FastfoodIcon className="customIcon" onClick={() => dashboardNavigateTo(0)}/></div>
                    <div className="icon"><FontAwesomeIcon icon={faUser} onClick={() => dashboardNavigateTo(11)}/></div>
                    <div className="icon"><FontAwesomeIcon icon={faQrcode} onClick={() => dashboardNavigateTo(12)}/></div>
                </div>
            </div>
        </StyledNavbar>
    )
}

export default Navbar;

