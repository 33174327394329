import React, {useEffect, useState} from 'react';
import Loader from "../components/common/Loader";
import {StyledProduct} from "./styled";
import {useDataContext} from "../context/DataContext";
import {useNavigate, useParams} from "react-router-dom";
import {Option, Product, ProductFormData} from "../types/types";
import {allergensMap, handleChangePrice, requireImg, tagsMap} from "../utils/utils";
import {useUtilitiesContext} from "../context/UtilitiesContext";
import {addProduct, removeProduct, updateProduct} from "../utils/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import ImageChoice from "../components/common/ImageChoice";
import DeleteObj from "../components/common/DeleteObj";
import {Switch} from "@mui/material";
import {useProductContext} from "../context/ProductContext";
import {useNotification} from "../context/NotificationContext";
import {useCategoryContext} from "../context/CategoryContext";

interface ProductPageProps {
    isUpdate?: boolean
    inCategory?: boolean
}

const ProductPage: React.FC<ProductPageProps> = ({ isUpdate, inCategory }) => {
    const { i18n } = useTranslation()
    const { loading: dataLoading, productsMap, ingredientsMap, productsOperation } = useDataContext()
    const { idProduct } = useParams();
    const { localname, transparentLoading, goBack, setTransparentLoading, setNavbarTitle, dashboardNavigateTo } = useUtilitiesContext();
    const { addNotification } = useNotification()

    let product = isUpdate ? productsMap.get(Number(idProduct)) : null;

    const [available, setAvailable] = useState<boolean>(product ? product.available : true);
    const [description, setDescription] = useState<string>(product?.description || '')
    const [displayValue, setDisplayValue] = useState<string>(product?.price ? (product?.price + "") : "0");
    const [file, setFile] = useState<File | null>(null);
    const [isDefault, setIsDefault] = useState<boolean>(false);
    const [img, setImg] = useState<string>(product?.img || '');
    const [name, setName] = useState<string>(product?.name || '')
    const [options, setOptions] = useState<Option[]>(product?.options || [])
    const [idCategory, setIdCategory] = useState<number>(1)
    const [originalIdCategory, setOriginalIdCategory] = useState<number>(1)
    const [optionDisplayValue, setOptionDisplayValue] = useState<string>('');
    const [optionName, setOptionName] = useState<string>('');
    const [optionsOpen, setOptionsOpen] = useState<boolean>(false)
    const [optionPrice, setOptionPrice] = useState<string>('');
    const [preview, setPreview] = useState<string | null>('')
    const [price, setPrice] = useState<number>(product?.price || 0);
    const [selectedAllergens, setSelectedAllergens] = useState<number[]>([...product?.allergens || []] || [])
    const [selectedIngredients, setSelectedIngredients] = useState<number[]>([...product?.ingredients || []] || [])
    const [selectedTags, setSelectedTags] = useState<number[]>([...product?.tags || []] || [])
    const [tmpPrice, setTmpPrice] = useState<number>(0)
    const { isNew, setTmpIsNew, productTmpFormData, loadTmpProduct, loadTmpProductFormData, manageIngredients, manageAllergens, manageTags, productSelected } = useProductContext()
    const { categorySelected, addProduct: addProductToList } = useCategoryContext()

    useEffect(() => {
        handleSetOptionPrice(tmpPrice)
    }, [tmpPrice]);

    useEffect(() => {
        if(!isUpdate)
            setNavbarTitle({ title: "Aggiungi Prodotto", iconRight: {type: "NONE", menu: []}})

        if(inCategory && !categorySelected && !isNew){
            window.location.href = "/" + localname
        }

        reload()
    }, []);

    const updateImage = (image: string, isPreview?: boolean, fileLoaded?: File | null) => {
        setPreview(isPreview ? image : null)
        setImg(isPreview ? '' : image)
        setFile(fileLoaded ? fileLoaded : null);
    }

    const reload = () => {
        if(!dataLoading) {
            if (isUpdate) {
                product = productsMap.get(Number(idProduct));

                let fromContext = true;
                if (product) {
                    if (!productSelected || productSelected.id !== product.id) {
                        loadTmpProduct(product)
                        fromContext = false;
                    }
                    setNavbarTitle({title: product.name || "", iconRight: {type: "NONE", menu: []}})
                    if(productTmpFormData) {
                        setName(productTmpFormData?.name);
                        setDescription(productTmpFormData?.description);
                        setImg(productTmpFormData?.img || "noImage.png");
                        setPrice(productTmpFormData?.price);
                        setOptions(productTmpFormData?.options)
                        setOriginalIdCategory(productTmpFormData?.originalIdCategory || 1)
                        setIdCategory(productTmpFormData?.idCategory || 1);
                        setDisplayValue(productTmpFormData?.price.toString() || product.price.toString())
                        setFile(productTmpFormData.file || null)
                        setPreview(productTmpFormData.preview || "")
                        setAvailable(productTmpFormData.available);
                    }else{
                        if(product) {
                            setName(product?.name);
                            setDescription(product?.description);
                            setImg(product?.img || "noImage.png");
                            setPrice(product?.price);
                            setOptions(product?.options)
                            setIdCategory(product?.idCategory || 1);
                            setOriginalIdCategory(product?.idCategory || 1)
                            setDisplayValue(product?.price.toString())
                            setSelectedAllergens((fromContext ? [...manageAllergens.existing, ...manageAllergens.added].filter((element) => !manageAllergens.removed.includes(element)) : product.allergens) || []);
                            setSelectedIngredients((fromContext ? [...manageIngredients.existing, ...manageIngredients.added].filter((element) => !manageIngredients.removed.includes(element)) : product.ingredients) || []);
                            setSelectedTags((fromContext ? [...manageTags.existing, ...manageTags.added].filter((element) => !manageTags.removed.includes(element)) : product.tags) || []);
                            setAvailable(product.available);
                        }
                    }
                }
            }else{
                if(!isNew)
                    setTmpIsNew()
                else{
                    if(productTmpFormData) {
                        setName(productTmpFormData?.name);
                        setDescription(productTmpFormData?.description);
                        setImg(productTmpFormData?.img || "noImage.png");
                        setPrice(productTmpFormData?.price);
                        setOptions(productTmpFormData?.options)
                        setIdCategory(productTmpFormData?.idCategory || 1);
                        setOriginalIdCategory(productTmpFormData?.originalIdCategory || 1)
                        setDisplayValue(productTmpFormData?.price.toString())
                        setFile(productTmpFormData.file || null)
                        setPreview(productTmpFormData.preview || "")
                        setAvailable(productTmpFormData.available);
                    }
                }
            }
        }
    }

    useEffect(() => {
        reload()
    }, [dataLoading]);

    useEffect(() => {
        setSelectedIngredients([...manageIngredients.existing, ...manageIngredients.added].filter((element) => !manageIngredients.removed.includes(element)))
    }, [manageIngredients]);

    useEffect(() => {
        setSelectedAllergens([...manageAllergens.existing, ...manageAllergens.added].filter((element) => !manageAllergens.removed.includes(element)))
    }, [manageAllergens]);

    useEffect(() => {
        setSelectedTags([...manageTags.existing, ...manageTags.added].filter((element) => !manageTags.removed.includes(element)))
    }, [manageTags]);

    const updateSaveFunc = async () => {
        setTransparentLoading(true);
        const formData: FormData = new FormData();
        if (isUpdate && product) {
            formData.append('id', product.id?.toString() || "");
        }
        formData.append('name', name);
        formData.append('price', price.toString());
        formData.append('description', description);
        formData.append('img', img);
        formData.append('available', available.toString());
        selectedIngredients.forEach((ingredient: number) => {
            formData.append('ingredients[]', ingredient.toString());
        });

        selectedAllergens.forEach((allergen: number) => {
            formData.append('allergens[]', allergen.toString());
        });

        selectedTags.forEach((tag: number) => {
            formData.append('tags[]', tag.toString());
        });

// Converti l'array di oggetti in JSON e aggiungi al FormData
        if(options.length > 0)
            formData.append('options', JSON.stringify(options));
        if (file) {
            formData.append('file', file);
        }
        formData.append('idCategory', idCategory.toString() || "");
        formData.append('originalIdCategory', originalIdCategory.toString() || "");

        let tmp: any = isUpdate ? await updateProduct(formData) : await addProduct(formData)

        if(tmp){
            if((isUpdate && product?.id) || !isUpdate) {
                productsOperation({operation: isUpdate ? "UPDATE" : "ADD"}, {
                    id: isUpdate && product?.id ? product.id : tmp.id,
                    name: name,
                    price: price,
                    description: description,
                    img: (isUpdate ? tmp : tmp.img) || null,
                    available: available,
                    ingredients: [...selectedIngredients],
                    allergens: [...selectedAllergens],
                    tags: [...selectedTags],
                    idCategory: idCategory,
                    options: options
                })
            }
            addNotification('success', i18n.t("Notifications.confirmed"));
            if(!isUpdate && inCategory){
                addProductToList(tmp)
            }
            setTransparentLoading(false);
            goBack()
        }else{
            addNotification('error', i18n.t("Notifications.error"))
        }
        //if(transparentLoading)
            setTransparentLoading(false)
    }

    const addOption = () => {
        if (optionName && optionPrice) {
            let newOption: Option = { name: optionName, price: Number(Number(optionPrice).toFixed(2)), isDefault };

            if (isDefault || options.length === 0) {
                newOption.isDefault = true;
                const updatedOptions = options.map(opt => ({ ...opt, isDefault: false }));
                setOptions([...updatedOptions, newOption]);
            } else {
                setOptions([...options, newOption]);
            }

            setOptionName('');
            setOptionPrice('');
            setOptionDisplayValue('');
            setIsDefault(false);
        } else {
            //addNotification('error', 'Nome e prezzo dell\'opzione sono obbligatori');
        }
    }

    const handleSetOptionPrice = (priceOpt: number) => {
        setOptionPrice(priceOpt.toString())
    }

    const removeOption = (indexToRemove: number) => {
        const newOptions = options.filter((_, index) => index !== indexToRemove);

        if (options[indexToRemove].isDefault && newOptions.length > 0) {
            const updatedOptions = newOptions.map((opt, index) => ({
                ...opt,
                isDefault: index === 0
            }));
            setOptions(updatedOptions);
        } else {
            setOptions(newOptions);
        }
    }

    const navigateToListOfElements = (type: string) => {
        const productTmp: ProductFormData = {
            name: name,
            description: description,
            img: img,
            available: available,
            file: file,
            preview: preview,
            price: price,
            options: options,
            idCategory: idCategory,
            originalIdCategory: originalIdCategory
        }
        loadTmpProductFormData(productTmp)
        dashboardNavigateTo(1, product?.id ? product.id + "/" + type : "0/" + type )
    }

    const deleteFunc = async () => {
        if(isUpdate && !transparentLoading && product && product.id){
            setTransparentLoading(true)
            const tmp: any = await removeProduct(product.id, product.idCategory)
            if(tmp){
                productsOperation({operation: "REMOVE"}, undefined, product.id)
                addNotification('success', i18n.t("Notifications.confirmed"))
                setTransparentLoading(false)
                goBack()
            }else{
                addNotification('error', i18n.t("Notifications.error"));
            }
            if(transparentLoading)
                setTransparentLoading(false)
        }
    }

    return (
        <>
            {(dataLoading) ? <Loader allPage={true}/> :
                <StyledProduct>
                    {transparentLoading && <Loader allPage={true} transparent={true}/>}
                    <ImageChoice text={"Carica un'immagine per il tuo piatto"} setPreview={updateImage}
                                 image={preview && preview !== '' ? preview : requireImg(img, localname || "")}/>

                    <div className={"line"}></div>

                    <div className={"box"}>
                        <div className={"title"}>
                            Nome piatto
                        </div>
                        <input
                            placeholder="Inserisci il nome"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div className={"line"}></div>

                    <div className="boxSwitch">
                        <div className="titleSwitch">Disponibile</div>
                        <div className={"switch"}>
                            <Switch
                                checked={available}
                                onChange={(e) => setAvailable(e.target.checked)}
                            />
                        </div>
                    </div>

                    <div className={"line"}></div>

                    <div className={"box"}>
                        <div className={"title"}>
                            Descrizione
                        </div>
                        <input
                            placeholder="Inserisci la descrizione"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>

                    <div className={"line"}></div>

                    <div className={"box"} onClick={() => navigateToListOfElements("ingredients")}>
                        <div className={"title"}>
                            Ingredienti
                            <span className={"iconBoxList"}>
                                <FontAwesomeIcon icon={faAngleRight}/>
                            </span>
                        </div>
                        {selectedIngredients?.length > 0 ?
                            <div className={"boxList"}>
                                {[...selectedIngredients].map((ingredient) =>
                                    ingredientsMap.has(ingredient) &&
                                    <div className="allergen-item-row">{ingredientsMap.get(ingredient)?.name}</div>
                                )}
                            </div>
                            :
                            <div>Aggiungi ingredienti</div>
                        }
                    </div>

                    <div className={"line"}></div>

                    <div className={"box"} onClick={() => navigateToListOfElements("allergens")}>
                        <div className={"title"}>
                            Allergeni
                            <span className={"iconBoxList"}>
                                <FontAwesomeIcon icon={faAngleRight}/>
                            </span>
                        </div>
                        {selectedAllergens?.length > 0 ?
                            <div className={"boxList"}>
                                {[...selectedAllergens].map((allergen) =>
                                    allergensMap && allergensMap.has(allergen) &&
                                    <div className="allergen-item-row">{allergensMap?.get(allergen)?.name || ""}</div>
                                )}
                            </div>
                            :
                            <div>Aggiungi allergeni</div>
                        }
                    </div>

                    <div className={"line"}></div>

                    <div className={"box"} onClick={() => navigateToListOfElements("tags")}>
                        <div className={"title"}>
                            Tag
                            <span className={"iconBoxList"}>
                                <FontAwesomeIcon icon={faAngleRight}/>
                            </span>
                        </div>
                        {selectedTags.length > 0 ?
                            <div className={"boxList"}>
                                {[...selectedTags].map((tag) =>
                                    tagsMap && tagsMap.has(tag) &&
                                    <div className="item-row">{tagsMap?.get(tag)?.name}</div>
                                )}
                            </div>
                            :
                            <div>Aggiungi Tag</div>
                        }
                    </div>

                    <div className={"line"}></div>

                    <div className="box">
                        <div className="title">Opzioni</div>
                        {options.length > 0 && (
                            <div className="existing-options">
                                {options.map((option, index) => (
                                    <div key={index} className="option">
                                        {option.name} - € {option.price} {option.isDefault && "(Default)"}
                                        <button onClick={() => removeOption(index)}>Rimuovi</button>
                                    </div>
                                ))}
                            </div>
                        )}
                        {!optionsOpen && (
                            <div>
                                <button className="add-option-button" onClick={() => setOptionsOpen(true)}>
                                    Aggiungi opzione
                                </button>
                            </div>
                        )}

                        {optionsOpen && options.length < 3 &&
                            <div className="option-inputs">
                                <input
                                    placeholder="Nome opzione"
                                    value={optionName}
                                    onChange={(e) => setOptionName(e.target.value)}
                                />
                                <input
                                    placeholder="Prezzo opzione"
                                    value={"€ " + optionDisplayValue}
                                    onChange={(e) => handleChangePrice(e, setOptionDisplayValue, setTmpPrice)}
                                />
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isDefault}
                                        onChange={() => setIsDefault(!isDefault)}
                                    />
                                    Default
                                </label>
                                <button onClick={addOption}>Aggiungi opzione</button>
                            </div>
                        }
                    </div>

                    <div className={"line"}></div>

                    {options.length === 0 && (
                        <div className="box">
                            <div className="title">Prezzo</div>
                            <div>
                                <input
                                    placeholder="Inserisci il prezzo"
                                    value={"€ " + displayValue}
                                    onChange={(e) => handleChangePrice(e, setDisplayValue, setPrice)}
                                />
                            </div>
                        </div>
                    )}

                    {isUpdate && <>
                        <div className={"line"}></div>

                        <div className={"boxDelete"}>
                            <DeleteObj
                                remove={deleteFunc}
                                text={"Elimina piatto"}
                                messageTitle={i18n.t('Dashboard.products.deleteTitle')}
                                messageBody={i18n.t('Dashboard.products.deleteBody')}/>
                        </div>
                    </>}

                    <div className={"line"}></div>

                    <div className={"lastLine"}></div>

                    <div
                        className={(name === '') ? "saveButtonNotActive" : "saveButtonActive"}
                        onClick={() => name !== '' && updateSaveFunc()}
                    >
                        Salva
                    </div>
                </StyledProduct>
            }
        </>
    );
};

export default ProductPage;