import React, {useEffect, useState} from 'react';
import {useDataContext} from "../context/DataContext";
import {useUtilitiesContext} from "../context/UtilitiesContext";
import {useLoginContext} from "../context/LoginContext";
import Loader from "../components/common/Loader";
import {StyledPage} from "./styled";
import {handleChangePrice} from "../utils/utils";
import {useNotification} from "../context/NotificationContext";

const ChangePasswordPage: React.FC = () => {
    const { loading: dataLoading } = useDataContext()
    const { changePasswordFunc } = useLoginContext()
    const { setNavbarTitle, setTransparentLoading, goBack  } = useUtilitiesContext()
    const [password, setPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const { user } = useLoginContext()
    const { addNotification } = useNotification()

    useEffect(() => {
        setNavbarTitle({back: {back: true, custom: false}, title: "Cambia password", iconRight: {type: "NONE", menu: []}})
    }, []);

    const changePassword = async () => {
        setTransparentLoading(true)
        const response = await changePasswordFunc(password, newPassword);
        if(response === "SUCCESS"){
            addNotification('success', 'Password modificata');
            goBack()
        }else{
            addNotification('error', response)
        }
        setTransparentLoading(false)
    }

    return (
        <>
        {(dataLoading) ? <Loader allPage={true}/> :
            <StyledPage>

                <div className="box">
                    <div className="title">Vecchia password</div>
                    <div>
                        <input
                            placeholder="Inserisci la vecchia password"
                            value={password}
                            type={'password'}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>

                <div className={"line"}></div>

                <div className="box">
                    <div className="title">Nuova password</div>
                    <div>
                        <input
                            placeholder="Inserisci la nuova password"
                            value={newPassword}
                            type={'password'}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>
                </div>

                <div className={"line"}></div>

                <div className={"lastLine"}></div>

                <div
                    className={(password === '' || newPassword === '') ? "saveButtonNotActive" : "saveButtonActive"}
                    onClick={() => (password !== '' && newPassword !== '') && changePassword()}
                >
                    Salva
                </div>
            </StyledPage>
        }
        </>
    );
};

export default ChangePasswordPage;