import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useNavigate, useParams} from "react-router-dom";
import {checkCode} from "../utils/api";

const AccountConfirmationPage = () => {
    const navigate = useNavigate()
    const {id, code} = useParams()
    const [verified, setVerified] = useState<boolean>(false)
    const handleLoginRedirect = () => {
        navigate('/login');
    };

    useEffect(() => {
        try {
            const doc = document.getElementById('root')
            if(doc) {
                doc.style.maxWidth = "100%";
            }
        }catch (error){

        }
        check()
    }, []);

    const check = async () => {
        if (id && code) {
            const tmp = await checkCode(Number(id), code);
            if(tmp){
                setVerified(true)
            }else{
                setVerified(false);
            }
        }else{
            setVerified(false)
        }
    }

    return (
        <Container>
            <Content>
                <Title>{verified ? "Account Confermato con Successo" : "Errore"}</Title>
                <Message>{verified ? "Il tuo account è stato confermato con successo! Puoi ora effettuare il login." : "L'url non è corretto, oppure non è stato possibile confermare l'account."}</Message>
                {verified && <Button onClick={handleLoginRedirect}>Vai al Login</Button>}
            </Content>
        </Container>
    );
};

export default AccountConfirmationPage;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #f0f4f8, #d9e2ec);
    padding: 20px;
`;

const Content = styled.div`
    text-align: center;
    background: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
`;

const Title = styled.h1`
    font-size: 24px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.primary};
`;

const Message = styled.p`
    font-size: 16px;
    margin-bottom: 30px;
    color: #333;
`;

const Button = styled.button`
    padding: 12px 24px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${({ theme }) => theme.colors.secondary};
    }
`;
